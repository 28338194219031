import apiRequest from "../api/apiRequest";
import { Footer } from "../../opus2/footer/footer";
import { apiConfig } from "../config/apiConfig";
import { getCountryCode } from "../location-search-map/getCountryCode";
import { Modal } from 'flowbite';
import { replaceLocalLogoLink } from "../util/replaceLocalLogoLink";
import { checkOSFlowPathAndReturnBoolean, getBrandDetailsFromBrandJson, getWeblocationPathGroupWithSiteConfig, updateHeaderContent } from "../util/share";
import { reportScreenSize } from "../location-search-map/reportScreenSize";
import { ScreenType } from "../location-search-map/models/ScreenType";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiCaching } from "../config/cachingConfig";

export class SetLocalHeader {
    screenType!: ScreenType;
    private isApp = false;
    constructor() {
        this.screenType = reportScreenSize();
        const dbaName : any = document.getElementById('dbaName');

        this.modalOptions()
        // ends

        if(dbaName){
            localStorage.setItem('dbaName', dbaName?.value)
        }
        const webId : any = document.getElementById('weblocationId')
        if(webId){
            localStorage.setItem('weblocationId', webId?.value)
        }

        if (localStorage.getItem('weblocationId')){
            const inpu1 : any = document.createElement('input');
            inpu1.type = 'hidden';
            inpu1.value = localStorage.getItem('dbaName');
            inpu1.id = 'full_dbaName';
            const inpu2: any = document.createElement('input');
            inpu2.type = 'hidden';
            inpu2.value = localStorage.getItem('weblocationId');
            inpu2.id = 'local_weblocationId';
            document.body.appendChild(inpu1);
            document.body.appendChild(inpu2);
            
        }

        (async  () => {
            try {
            this.isApp = await checkOSFlowPathAndReturnBoolean();    
            } catch (error) {
                console.log("Error occurred in checkOSFlowPathAndReturnBoolean function: " + error);
            }
            this.setHeader();
        })();

       
    }

    public modalOptions() {
        const options: any = {
            placement: 'center',
            backdrop: 'static',
            backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
            closable: true,
            onHide: () => {
            },
            onShow: () => {
            },
            onToggle: () => {
            }
          };

          const targetElement = document.getElementById("glb-find-local");
          const modal = new Modal(targetElement, options);
          return modal;
    }
    public modalToggle() {
        const localHeader = document.querySelector('.find-local');
             localHeader?.addEventListener("click", (e) => {
                e.preventDefault()
                this.modalOptions().show();
             })
             const closeModalID = document.getElementById("close-local-modal");
                                closeModalID?.addEventListener("click", () => {
                                    this.modalOptions().hide();
                                    setTimeout(() =>document.querySelector("body > div[modal-backdrop]")?.remove(), 0);
                                });
    }
    private setMobileLocalHeader(){
        const mobLocalHeader = document.querySelector('.find-local-mobile');
        const dbaName: string | null = localStorage.getItem('dbaName');
        if(sessionStorage.getItem('franchiseWebLocationId') && dbaName){
            const newHTML = `
                <svg class="icon"> <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use></svg>
                    <div>
                        <span class="local-address">${sessionStorage.getItem('doingBusinessAs')}</span> Change location
                    </div>
                `;
            if (mobLocalHeader ) {
                mobLocalHeader.innerHTML = newHTML;
            }
            this.setHamburgerMenuDba(newHTML);
            this.setHamburgerSubMenuInnerDba(newHTML);
        }
    }

    private setHeader() {
        
        const mobLocalHeader = document.querySelector('.find-local-mobile');
        const dbaName: string | null | any = localStorage.getItem('dbaName');
        const webId: string | null = localStorage.getItem('weblocationId');
        const phoneNumberEle:any = document.querySelector('.header-wrapper .call-cta') || document.querySelector('.header-wrapper .form-call-cta');
        const phoneNumber = sessionStorage.getItem('localPhoneNumber');
        const locationDoingBusinessAs = sessionStorage.getItem('doingBusinessAs');

        this.setSubtext();
        this.setMobilePhoneNumber(); // set call us number in mobile view

        if(locationDoingBusinessAs){
            updateHeaderContent(locationDoingBusinessAs);
        }else {
            const visibilityTrue : boolean = true;
            this.customizeBrandHeader(visibilityTrue);
        }

        if (phoneNumberEle && phoneNumber) {
            phoneNumberEle.href = `tel:${phoneNumber}`
            const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
            const phoneFormat: any = String(phoneNumber)?.replace(/\D/g, '').match(reg);
            phoneNumberEle.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
            phoneNumberEle.setAttribute('data-title',`(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`);
        }
        if (sessionStorage.getItem('franchiseWebLocationId') && !this.isApp && dbaName && !window.location.href.toLowerCase().includes(dbaName.toLowerCase())){
             this.modalToggle();
             const newHTML = `
                <svg class="icon"> <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use></svg>
                    <div>
                        <span class="local-address">${sessionStorage.getItem('doingBusinessAs')}</span> Change location
                    </div>
                `;
            if (mobLocalHeader) {
                mobLocalHeader.innerHTML = newHTML;
            }

            this.setHamburgerMenuDba(newHTML); 
            this.setHamburgerSubMenuInnerDba(newHTML);   

            const MobileLogo = document.querySelector('.mobile-menu-logo .brand-logo-mobile') as HTMLElement;
            if(MobileLogo){
                replaceLocalLogoLink(MobileLogo);
            }
            const MobileInnerLogo = document.querySelector('.sidenav-logo-cta .sidenav-brand-logo') as HTMLElement;
            if(MobileInnerLogo){
                replaceLocalLogoLink(MobileInnerLogo);
            }
            const FooterBrandLogo = document.querySelector('.brand-footer .fb-des-link') as HTMLElement;
            if(FooterBrandLogo){
                replaceLocalLogoLink(FooterBrandLogo);
            }
        }
           
        else {
            const webId = (document.getElementById('weblocationId') as HTMLInputElement)?.value || sessionStorage.getItem("franchiseWebLocationId");
            if (webId && !this.isApp) {
                try {
                    const request = {
                        method: 'GET',
                        url: `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${webId}`,
                    };
                    
                    apiRequest(request)
                        .then((resp: any) => {
                           let callTrack:any
                           resp?.callTrackings.forEach((type:any)=>{
                                if(type.callTrackingTypeDescription === "WebsiteTracking"){
                                    callTrack = type.callTrackingNumber;
                                }
                            });
                            if(!callTrack){
                                callTrack = resp?.mainPhoneNumber;
                            }
                            sessionStorage.setItem('franchiseWebLocationId', resp.franchiseWebLocationId);
                            sessionStorage.setItem('doingBusinessAs', resp.locationDoingBusinessAs);
                            sessionStorage.setItem('localPhoneNumber', callTrack);
                            let countryCode = getCountryCode();
                            sessionStorage.setItem('countryCode', countryCode);
                            
                            const mobLocalHeader = document.querySelector('.find-local-mobile');
                            const phoneNumberEle:any = document.querySelector('.header-wrapper .call-cta') || document.querySelector('.header-wrapper .form-call-cta');
                            const phoneNumber = sessionStorage.getItem('localPhoneNumber');
                            const dbaName = document.getElementById("full_dbaName");
                            
                            this.setMobilePhoneNumber(); // set call us number in mobile view

                            if (phoneNumberEle && phoneNumber) {
                                phoneNumberEle.href = `tel:${phoneNumber}`;
                                const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                                const phoneFormat: any = String(phoneNumber)?.replace(/\D/g, '').match(reg);
                                phoneNumberEle.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
                                phoneNumberEle.setAttribute('data-title',`(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`);
                                
                            }
                            
                            if(dbaName){
                                const locationDoingBusinessAs =  resp?.locationDoingBusinessAs
                                updateHeaderContent(locationDoingBusinessAs);
                                const visibilityTrue : boolean = false;

                                this.customizeBrandHeader(visibilityTrue)
                                this.modalToggle();
                            }
                            if (resp?.locationDoingBusinessAs) {
                                const newHTML = `
                                <svg class="icon"> <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use></svg>
                                    <div>
                                        <span class="local-address">${resp?.locationDoingBusinessAs}</span> Change location
                                    </div>
                                `;
                                if (mobLocalHeader) {
                                    mobLocalHeader.innerHTML = newHTML;
                                }
                            
                                this.setHamburgerMenuDba(newHTML);  
                                this.setHamburgerSubMenuInnerDba(newHTML);

                            }


                     const ft_footer =    new Footer()

                     ft_footer.appendSocialLinks(resp?.franchiseWebLocationLinks);
                     ft_footer.changeFooterAddress(resp);
                    //  const no = resp?.callTrackings[0] ? resp?.callTrackings[0].callTrackingNumber: resp.mainPhoneNumber 
                     ft_footer.setMobileNumber(callTrack);
  
                    })

                        .catch((err) => {
                        });
                } catch (error) {

                }

            }
        }

    }

    private async setSubtext() {
        const subtext = document.querySelector('.enable-disable-subtext-header') as HTMLElement;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    
        if (!subtext) {
            console.log("Subtext class not authored from CP, please add the required class for subtext");
            return;
        }
    
        const isLocalized = this.checkLocalization();
        if (isLocalized) {
            await this.handleLocalizedSubtext(subtext, brandData);
        } else {
            this.handleNationalSubtext(subtext, brandData);
        }
    }
    
    private checkLocalization(): boolean {
        const dbaName = localStorage.getItem('dbaName');
        return !!(sessionStorage.getItem('franchiseWebLocationId') && dbaName);
    }
    
    private async handleLocalizedSubtext(subtext: HTMLElement, brandData: any) {
        const urlSiteConfig = getWeblocationPathGroupWithSiteConfig();
        if (!urlSiteConfig) return;
    
        try {
            const cachedResponse = await getCachedAPIResponse(urlSiteConfig, apiCaching.SiteConfig, false);
            if (cachedResponse) {
                if (cachedResponse.disable_SubText_Local) {
                    subtext.classList.add('hidden');
                } else {
                    this.updateSubtext(subtext, brandData);
                }
            }
        } catch {
            console.error("Error in fetching site config");
        }
    }
    
    private handleNationalSubtext(subtext: HTMLElement, brandData: any) {
        if (brandData?.disable_SubText_National) {
            subtext.classList.add('hidden');
        } else {
            this.updateSubtext(subtext, brandData);
        }
    }
    
    private updateSubtext(subtext: HTMLElement, brandData: any) {
        subtext.classList.remove('hidden');
        subtext.innerHTML = brandData?.subText_value ?? "";
    }
    
    

    private setMobilePhoneNumber(){

        const phoneNumber = sessionStorage.getItem('localPhoneNumber');
        const mobileNumberEle:any = document.querySelectorAll('.mob-call-cta');
        
        if (mobileNumberEle && phoneNumber) { 
            mobileNumberEle.forEach((ele : any) => {
                ele.href = `tel:${phoneNumber}`;
            });
        }
    }

    private customizeBrandHeader(visibilityTrue:boolean) {
        const brandHeader = document.querySelector(".brand-header") as HTMLElement;
        const brandLogo = brandHeader?.querySelector(".brand-logo-wrapper") as HTMLElement;
    
        if (brandLogo) {
            brandLogo.style.display = visibilityTrue &&  this.screenType === ScreenType.Desktop ? "flex" : "none";
        }
    
        const findLocal = brandHeader?.querySelector(".find-local") as HTMLElement;
    
        if(findLocal){
            if( this.screenType === ScreenType.Desktop){

                findLocal.style.display = visibilityTrue ? "flex" : "none";
            }else{

                findLocal.style.display = "flex";
            }
        }
    }
    public setHamburgerMenuDba(newHTML:any):any{

        if(this.screenType !== ScreenType.Desktop){
            const findLocalElement = document.querySelector('.brand-header .find-local');
            if(findLocalElement) {
                findLocalElement.innerHTML = newHTML;       
            }
            return findLocalElement;
        }
    }
    public setHamburgerSubMenuInnerDba(newHTML:any):any{
    
        if(this.screenType !== ScreenType.Desktop){
            const localDBALevel2 = document.querySelectorAll('.main-nav-warpper .find-local');
            if(localDBALevel2){
                localDBALevel2?.forEach((item:any)=>{
                    item.innerHTML = newHTML;
                })
            }
        }
    }
   
}
document.addEventListener('DOMContentLoaded', ()=>{
    new SetLocalHeader();
})


