import moment from "moment";
import { stopLoader } from "../util/loader";
import { callBookingApi, checkTechAvailability, configureMollyEstimate, configureNotesComments } from "./callBookingApi";
import { tns } from "tiny-slider";
import { validEstimate } from "./confirmation";

let date: any, time: any, schaduleId: any, numSlots:any;
function removeDuplicateDates(resultCollection: any) {
    let filteredResultCollection: any = [];
    let dataSet = new Set();
    resultCollection.forEach((result:any)=>{
        if(!dataSet.has(result.startDate)){
            dataSet.add(result.startDate);
        }
    });
    
    resultCollection.forEach((result:any)=>{
        if(dataSet.has(result.startDate)){
            const it = {
                "dateId": result.$id as number,
                "isAvailable": result.isAvailable as boolean,
                "startDate": result.startDate.split('T')[0] as string,
                "scheduleID": result.scheduleID as number,
                "displayText": result.displayText as string
            };
            filteredResultCollection.push(it);
        }
    });
   
    numSlots = getSlotPeriodicity(filteredResultCollection);
    return filteredResultCollection;
}

function setCard(data: any) {
    let uniqueDates = new Set();
    return data.map((item: any) => {
        const filteredData: any = data?.some((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item.startDate).format('DD MMM, YYYY') && x.isAvailable);
        if (!uniqueDates.has(item.startDate)) {
            uniqueDates.add(item.startDate);
            return (`
                <li class="date-details-item">
                <button type="button" class="${!filteredData ? 'date-details-disable' : moment(item.startDate).format('ddd DD MMM, YYYY') === moment(date).format('ddd DD MMM, YYYY') ? 'date-details-inactive date-details-active dateapp' : 'date-details-inactive dateapp'}"
                value=${item.startDate}>
                    <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('MMM')}</span>
                    <strong value=${item.startDate} class="date-text-normal">${moment(item.startDate).format('DD')}</strong>
                    <input class="getDateApp" hidden value=${item.startDate}>
                    <input class="scheduleID" hidden value=${item.scheduleID}>                                
                    <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('ddd')} </span>
                </button>
            </li>
            `)
        }

    })
}

function setTinySlider(data: any, currentIndex: any) {
    try {
        tns({
            container: '.mly-date-slider',
            loop: false,
            gutter: 8,
            items: 7,
            slideBy: 'page',
            nav: false,
            autoplay: false,
            speed: 400,
            swipeAngle: false,
            autoplayButtonOutput: false,
            mouseDrag: true,
            lazyload: true,
            startIndex: currentIndex,
            controlsContainer: "#customize-controls",
            responsive: {
                350: {
                    items: 5,
                },
                640: {
                    items: 5,
                },
                768: {
                    items: 5,
                },
                1024: {
                    items: 7,
                }
            }
        });
        
    } catch (error) {

    }
}

function handleLeadFlow(bookingPayload: any) {
    bookingPayload.IsLeadOnly = true;
    sessionStorage.setItem('IsLeadOnly',bookingPayload.IsLeadOnly);
    callBookingApi(bookingPayload);
}

function initCalendarScreen(bookingPayload: any) {
    const elems = document.querySelectorAll('#form-section .form-step');
    elems.forEach((el: any) => {
        if (el?.className.includes('appointment-details-section')) {
            el.classList.add('form-step-active');
        }
        else {
            el.classList.remove('form-step-active');
        }
    });
    let progressBar = document.querySelector("#progress") as HTMLElement;
    progressBar.style.width = "100%";
    let bars = document.querySelectorAll('.progress-step');
    bars[1].classList.add("prograssbar-length");
    bars[1].classList.add("progress-step-active");
    const type = bookingPayload.JobFrequency?.toLowerCase();
    const recurheading = document.querySelector('.recurring-estimation-heading') as HTMLElement;
    const otheading = document.querySelector('.one-time-heading-wrap') as HTMLElement;
    const legalText = document.querySelector('.heading-thanyou-text');

    if (type?.includes('recurring')) {
        recurheading.style.display = 'flex';
        otheading.style.display = 'none';
        bookingPayload.ServiceText = 'Estimate';
    } else {
        recurheading.style.display = 'none';
        otheading.style.display = 'flex';
        bookingPayload.ServiceText = 'One Time Clean';
    }
    if (legalText && legalText?.textContent?.trim() !== '') {
        document.addEventListener('visibilitychange', async function() {
            if (document.hidden) {
                bookingPayload.IsLeadOnly = true;
                bookingPayload.ServiceText = 'Lead Only';
                await callBookingApi(bookingPayload, true);
            }
        });
   }

}

export function calendarFlowMLY(bookingPayload: any) {

    const div = document.querySelector('.calendar-container');
    if (div) {
        const mcfObj = new MollyCalendarFlow(bookingPayload);
        checkTechAvailability(mcfObj.jobFreq)
            .then((response: any) => {
                if (response?.resultCollection) {
                    const data = response?.resultCollection;
                    const availability = data.some((dt: any) => {
                        return dt.isAvailable;
                    });
                    if (availability) {
                        initCalendarScreen(bookingPayload);
                        let currentIndex = data.findIndex((dt: any) => dt.isAvailable);
                        date = data?.find((x: any) => x.isAvailable).startDate;
                        time = '';
                        const outStr = displayEstimate();
                        if(!mcfObj.bookingPayload.Note.includes(outStr)){
                            mcfObj.bookingPayload.Note = mcfObj.bookingPayload.Note.concat(outStr + ', ');
                            if(configureNotesComments()){
                                mcfObj.bookingPayload.Comments = mcfObj.bookingPayload.Comments.concat(outStr + ', ');
                            }
                            
                        }
                        let scId = data[currentIndex]?.scheduleID;
                        const filterData = removeDuplicateDates(data);
                        const cards = setCard(filterData);
                        if (mcfObj.dateSlider) {
                            mcfObj.dateSlider.innerHTML = '';
                            mcfObj.dateSlider.innerHTML = cards.join('');
                        }
                        mcfObj.selectedDateTime.innerHTML = `${date ? moment(date).format('ddd MMM DD') : "Please Select a Date"}, <span class="selected-time"> Please Select a Time </span>`;
                        const wrapperDiv = document.querySelector('.calendar-container .date-details-wrapper') as HTMLElement;
                        wrapperDiv.style.display = "flex";
                        mcfObj.addListner(data, scId);
                        let startIndex;
                        if(mcfObj.jobFreq.toLowerCase().includes("recurring")){
                            startIndex = Math.floor(currentIndex / 13);
                        }
                        else{
                            startIndex = Math.floor(currentIndex / numSlots);
                        }
                        
                        setTinySlider(data, startIndex);
                        const itemFInd = data[currentIndex];
                        mcfObj.setTimeUl(data, itemFInd);
                        stopLoader();
                    }
                    else {
                        console.log("Tech Availability API has no available slots => Submitting Lead flow ");
                        window.onbeforeunload = null;
                        handleLeadFlow(bookingPayload);
                    }

                }
                else {
                    console.log("Tech Availability API returns empty => Submitting Lead flow ");
                    window.onbeforeunload = null;
                    handleLeadFlow(bookingPayload);
                }

            })
            .catch((error) => {
                console.error("Tech Availability API fail=> Submitting lead flow:  ", error);
                window.onbeforeunload = null;
                handleLeadFlow(bookingPayload);
            });
    }
    else {
        console.error("Error in HTML");
    }

}

function displayEstimate():string {
    const divTag = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    let returnStr = '';
    configureMollyEstimate()
    .then((val:any)=>{
        const estFlag = (val && divTag) ? true: false;
        if (estFlag && validEstimate()){
            showEstimate();
            const estimate = sessionStorage.getItem('mollyEstimate');
            const estimateData = JSON.parse(estimate as string);
            const estimateContainer = document.querySelector('.one-time-amount') as HTMLElement;
            const frequencyCleaningOne = (document.getElementById('frequency-cleaning-one') as HTMLInputElement)?.checked;
            const oneTimeClean = (document.getElementById('ont-time-clean') as HTMLInputElement)?.checked;
            const moveOutClean = (document.getElementById('move-out-clean') as HTMLInputElement)?.checked;
            const dateDay = getDayOfWeek(date);
            if (frequencyCleaningOne && estimateData) {
                if(oneTimeClean){
                    if(dateDay === 'Saturday'){
                        estimateContainer.innerHTML = `$${estimateData?.estimateOccasionalRangeLowSaturday} - $${estimateData?.estimateOccasionalRangeHighSaturday}`;
                    }
                    else{
                        estimateContainer.innerHTML = `$${estimateData?.estimateOccasionalRangeLow} - $${estimateData?.estimateOccasionalRangeHigh}`;
                    }
                }
                else if(moveOutClean){
                    if(dateDay === 'Saturday'){
                        estimateContainer.innerHTML = `$${estimateData?.estimateMoveRangeLowSaturday} - $${estimateData?.estimateMoveRangeHighSaturday}`;
                    }
                    else{
                        estimateContainer.innerHTML = `$${estimateData?.estimateMoveRangeLow} - $${estimateData?.estimateMoveRangeHigh}`;
                    }
                }
                returnStr = `Price: {${estimateContainer.innerHTML}}`;
            }
        }
    }).catch((err:any)=>{
        sessionStorage.setItem("noCalendarPricing","true");
        returnStr = "No Calendar Pricing";
        hideEstimate();
    }); 
    
    return returnStr;

}

function hideEstimate(){
    const estimateDiv = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    if(estimateDiv){
        estimateDiv.classList.add('hidden');
    }
    
}
function showEstimate(){
    const estimateDiv = document.querySelector('.one-time-estimate-wrap') as HTMLElement;
    if(estimateDiv){
        estimateDiv.classList.remove('hidden');
    }
    
}

export function getDayOfWeek(dateString: any) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
}

export class MollyCalendarFlow {
    public dateSlider: HTMLElement;
    private availableHrs: HTMLElement;
    public selectedDateTime: HTMLElement;
    private requestCallBack: HTMLInputElement;
    private backBtn: HTMLButtonElement;
    private confirmBtn: HTMLButtonElement;
    public bookingPayload: any;
    private containerClass: HTMLElement;
    public jobFreq: any;
    constructor(payload: any) {
        this.bookingPayload = payload;
        this.jobFreq = this.bookingPayload.JobFrequency;
        this.dateSlider = document.querySelector('.date-details-wrapper #start-index') as HTMLElement;
        this.selectedDateTime = document.querySelector('.appointment-date-wrap .appointment-section-text') as HTMLElement;
        this.requestCallBack = document.querySelector('.sheduling-time-box #link-checkbox') as HTMLInputElement;
        this.backBtn = document.querySelector('.btn-container .btn-cancel') as HTMLButtonElement;
        this.confirmBtn = document.querySelector('.btn-container .primary-btn') as HTMLButtonElement;
        this.containerClass = document.querySelector('.calendar-container') as HTMLElement;
        this.initListeners();
        this.availableHrs = this.getCalendarRow();
    }

    initListeners() {
        this.backBtn.addEventListener('click', (e: any) => {
            e.preventDefault();
            const elems = document.querySelectorAll('#form-section .form-step');
            elems.forEach((el: any) => {
                if (el?.className.includes('appointment-details-section')) {
                    el.classList.remove('form-step-active');
                }
                else {
                    el.classList.add('form-step-active');
                }
            });
            let progressBar = document.querySelector("#progress") as HTMLElement;
            progressBar.style.width = "0%";
            let bars = document.querySelectorAll('.progress-step');
            bars[1].classList.remove("prograssbar-length");
            bars[1].classList.remove("progress-step-active");
        });

        this.confirmBtn.addEventListener('click', (e: any) => {
            e.preventDefault();
            console.log('Call booking API');
            if (this.requestCallBack.checked) {
                // Request callback flow
                this.bookingPayload.IsLeadOnly = true;
                this.bookingPayload.ServiceText = 'Lead Only';
                sessionStorage.setItem('IsLeadOnly',this.bookingPayload.IsLeadOnly);
            }
            if (this.validateForm()) {
                if (!this.requestCallBack.checked) {
                    this.storeDateTime();
                }
                this.bookingPayload.ScheduleID = schaduleId;
                if(sessionStorage.getItem('IsLeadOnly') == 'true'){
                    this.bookingPayload.IsLeadOnly = true;
                }
                else{
                    this.bookingPayload.IsLeadOnly = false;
                }
                window.onbeforeunload = null;
                callBookingApi(this.bookingPayload);
            }
        });
        this.requestCallBack.addEventListener('click', this.handleDisableApp.bind(this))
    }

    hideDateTimeError() {
        const dataTimeErrorModal = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
        if (dataTimeErrorModal) {
            dataTimeErrorModal.classList.add('hidden');
        }
        const requestCallbackErrorModal = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;
        if (requestCallbackErrorModal) {
            requestCallbackErrorModal.classList.add('hidden');
        }
    }

    private validateForm(): boolean {
        const dateTime = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
        const requestCallbackCheckbox = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;
        const linkCheckbox: HTMLInputElement | null = document.getElementById('link-checkbox') as HTMLInputElement | null;

        if ((date && time) || linkCheckbox?.checked) {
            return true;
        } else {
            if (dateTime) {
                dateTime.classList.remove('hidden');
                if (!dateTime?.innerText) {
                    dateTime.innerText = "Please select a date and time";
                }
            }
            if (requestCallbackCheckbox) {
                requestCallbackCheckbox.classList.remove('hidden');

                if (!requestCallbackCheckbox?.innerText) {
                    requestCallbackCheckbox.innerText = "or choose to get a call back.";
                }
            }
            return false;
        }
    }
    handleDisableApp() {
        if ( this.containerClass && this.requestCallBack.checked) {
            this.containerClass.classList.add('disable-calendar');
        }
        else if ( this.containerClass && !(this.requestCallBack.checked)) {
            this.containerClass.classList.remove('disable-calendar');
        }
        const callbackwrapper = document.querySelector('.callback-wrapper');
        if (callbackwrapper) {
            callbackwrapper?.classList.toggle('callback-requested');
        }

        this.hideDateTimeError();

    }
    getCalendarRow() {
        let rows: any;
        document.querySelectorAll('.calendar-row').forEach((row)=>{
            row.innerHTML = '';
        });
        if (this.jobFreq.toLowerCase().includes('recurring')) {
            rows = document.querySelector('.recurring-calendar');
        }
        else {
            rows = document.querySelector('.one-time-calendar');
        }
        return rows;
    }
    storeDateTime() {
        sessionStorage.setItem('selectedDay', getDayOfWeek(date));
        let timeOfDay:string;
        if(this.jobFreq.toLowerCase().includes('recurring')){
            timeOfDay = getTime(time);
        }
        else{
            timeOfDay = time;
        }   
        sessionStorage.setItem('timeOfDay', timeOfDay);
        sessionStorage.setItem('userSelectDatae', `${date}, ${time}`);
    }
    setTimeUl(allAppData: any, item: any) {
        const calendarRow = this.getCalendarRow();
        const sampleData: any = allAppData?.filter((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item?.startDate).format('DD MMM, YYYY'))
        const ulTimeData = sampleData?.map((x: any, i: any) => {
            return (`<li class="calendar-row-item"><button type="button" value="${x.scheduleID}" class="calendar-col btn-time  ${x.isAvailable == false ? 'disable-btn' : ''} "  id="${x.displayText}">${x.displayText}</button></li>`)
        })
        if (calendarRow) {
            calendarRow.innerHTML = ulTimeData.join('');
        }
        this.setListnerOnUlBtn();
    }

    addListner(allAppData: any, scId: any) {
        document.querySelectorAll('.dateapp')?.forEach((item: any) => {
            item.addEventListener('click', (e: any) => {
                const rm = document.querySelectorAll('.dateapp')
                rm.forEach(e => e.classList.remove('date-details-active'));
                date = moment(item.querySelector('.getDateApp').value).format('ddd DD MMM, YYYY');
                const outStr = displayEstimate();
                if(!this.bookingPayload.Note.includes(outStr)){
                    this.bookingPayload.Note = this.bookingPayload.Note.concat(outStr + ', ');
                    if(configureNotesComments()){
                        this.bookingPayload.Comments = this.bookingPayload.Comments.concat(outStr + ', ');
                    }
                    
                }
                time = '';
                item.classList.toggle('date-details-active');
                schaduleId = item.querySelector('.scheduleID').value;
                const itemFInd = allAppData?.find((x: any) => x.scheduleID == schaduleId)
                document.querySelector('.appointment-section-text')!.innerHTML = `${moment(item.querySelector('.getDateApp').value).format('ddd MMM DD')}, <span class="selected-time"> Please Select a Time </span>`;
                this.setTimeUl(allAppData, itemFInd);
            })
        })
    }

    setListnerOnUlBtn() {
        const timesBtn = document.querySelectorAll('.calendar-col');
        timesBtn.forEach(e => e.classList.remove('active'));
        let self = this;
        if (timesBtn) {
            timesBtn.forEach((item: any) => {
                item.addEventListener('click', (e: any) => {
                    timesBtn.forEach(e => e.classList.remove('active'));
                    item.classList.toggle('active');
                    const timecontainer = document.querySelector('.selected-time')
                    if (timecontainer)
                        timecontainer.innerHTML = item.innerHTML
                    schaduleId = item.value;
                    time = item.id;
                    self.hideDateTimeError();
                })

            })
        }
    }

    setAppointmentTimeText(data: any) {
        const timeButtons = document.querySelectorAll('.calendar-col.btn-time');
        timeButtons.forEach((item) => {
            data.forEach((element: any) => {
                if (item.innerHTML == element.displayText) {
                    item.setAttribute("value", element.scheduleID);
                    if (!element.isAvailable)
                        item.setAttribute("disabled", "");
                }
            });

        })
    }
}

function getTime(arg: string): string {
    const timeString = arg.trim().split(' - ')
    const parsedTime = moment(timeString, ['h:mm A', 'hh:mm A']);
    const formattedTime = Number(parsedTime.format('HH'));
    if (formattedTime <= 12) {
        return 'Morning';
    }
    else if (formattedTime > 12 && formattedTime <= 17) {
        return 'Afternoon';
    }
    else {
        return 'Evening'
    }

}

function getSlotPeriodicity(data:any){
    type Counts = {
        [key: string]: number;
    };
    const counts: Counts = {};
    data.forEach((entry: any) => {
        const { startDate } = entry;
        if (!counts[startDate]) {
            counts[startDate] = 0;
        }
        counts[startDate]++;
    });
    const date1 = date.split('T')[0];
    const specificDateCount = counts[date1] || 0;
    return specificDateCount;
}
