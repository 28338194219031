import { Cluster } from "./Cluster";
// import { FullAttributeContainer } from "./FullAttributeContainer";
import { getFranchiseWebLocations } from "./getFranchiseWebLocations";
import { FranchiseDetail } from "./models/FranchiseDetail";
import { renderMap } from "./renderMap";
import { reportScreenSize } from "./reportScreenSize";
import { ResultsList } from "./ResultsList";
import { ScreenType } from "./models/ScreenType";
import { Search } from "./Search";
import { startLoader, stopLoader } from "../util/loader";
import { locationSearchMapConfig } from './config';
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { getBrandDetailsFromBrandJson } from "../util/share";
import { getLocalization, getPageInfo } from "../util/add-Page-Type-DataLayer";

export class App {
    // webLocations: FranchiseDetail[] = [];
    webLocations: any;
    map!: H.Map;
    ui!: H.ui.UI;
    cluster!: Cluster;
    resultsList!: ResultsList;
    search!: Search;
    screenType!: ScreenType;
    // fullAttributeContainer: FullAttributeContainer;

    constructor() {
        // this.loadMapAndResultsList.call(this);

        this.init();
        this.windowResizeCallback();
    }
    async init() {
        await this.loadData();
        let locationList = document.querySelector(".location-list") as HTMLUListElement;
        if (locationList) {
            locationList.style.display = "block";
        }

        // stopLoader();       
    }
    async loadData() {
        this.webLocations = await getFranchiseWebLocations();
        this.loadMapAndResultsList();

    }
    async loadMapAndResultsList() {
        this.loadMapAndCluster();
        const mapUi = { map: this.map, ui: this.ui };
        // instantiate ResultsList
        this.resultsList = new ResultsList(mapUi, this.webLocations);

        const locationData = sessionStorage.getItem("nearbyLocations");
        let searchValue:any;
        if(locationData){
            const obj = JSON.parse(locationData);
            if(obj.containingData){
                searchValue = obj.resultData[0].doingBusinessAs;
            }
            else{
                searchValue = "";
            }
        }
        else{
            const locationInput = document.querySelector('.location-search-input') as HTMLInputElement;
            searchValue = locationInput.value;
        }
        // instantiate Search functionality
        this.search = new Search(mapUi.map, this.resultsList);
        this.search.search(searchValue);
    }
    async loadMapAndCluster() {
        this.screenType = reportScreenSize();
        const mapUi = renderMap(this.screenType);
        this.map = mapUi.map;
        this.ui = mapUi.ui;

        this.cluster = new Cluster(mapUi.map, mapUi.ui);
        this.cluster.startClustering(this.webLocations);
    }
    windowResizeCallback() {
        var newScreenType = reportScreenSize();
        if (this.screenType === ScreenType.Desktop) {
            if (this.screenType === newScreenType) {
                this.map.getViewPort().resize();
            }
        }
    }

}
document.addEventListener('DOMContentLoaded', async() => {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    await getBrandDetailsFromBrandJson(conceptCode)
    try {
        const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;
        if (pageType && pageType.toLocaleLowerCase() == "opus 2 lead flow confirmation page") {
            const franchiseWebLocationId = sessionStorage.getItem("RL_Weblocation_ID");
            const doingBusinessAs = sessionStorage.getItem("RL_DBAName");
            const leadObj = {
                event: 'custEv_LocalDataLayer',
                locationID: franchiseWebLocationId,
                locationDBA: doingBusinessAs
            }
            if (franchiseWebLocationId && doingBusinessAs)
                gtmCustomEventLeadFlow(leadObj);
        }
    } catch (error) {
        console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
    }
    try{
 
        const pageType = getPageInfo()
        const pageLocalization = getLocalization();
            let pageData = {
                event: 'pageDataLayer',
                pageType,
                localization: pageLocalization
                }
                gtmCustomEventLeadFlow(pageData)

    }catch(error){
        console.log("An error occurred in adding PagetypeDatalayer: ", error);
    }
    
    const container = document.querySelector('.find-location-section');
    if (container) {
        startLoader();
        const app = new App();
        window.addEventListener('resize', () => {
            app.windowResizeCallback();
        });
        stopLoader();
    }

});
