async function initializeDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        // Increment the version number to trigger onupgradeneeded if necessary
        const request = indexedDB.open('sessionDB', 2); 

        request.onupgradeneeded = (event: any) => {
            const db = event.target.result;
            // Check if 'sessionStore' exists, and create if it doesn't
            if (!db.objectStoreNames.contains('sessionStore')) {
                db.createObjectStore('sessionStore', { keyPath: 'id' });
            }
        };

        request.onsuccess = (event: any) => {
            resolve(event.target.result);
        };

        request.onerror = () => {
            console.error('Error opening database');
            reject(new Error('Error opening database'));
        };
    });
}

async function saveSessionId(id: string) {
    const db = await initializeDatabase();
    const transaction = db.transaction(['sessionStore'], 'readwrite');
    const store = transaction.objectStore('sessionStore');
    store.put({ id: 'sessionId', value: id });

    transaction.oncomplete = () => {
        console.log('Session ID saved successfully.');
    };

    transaction.onerror = (event: any) => {
        console.error('Error saving session ID:', event.target.error);
    };
}

async function getSessionId(): Promise<string | null> {
    const db = await initializeDatabase();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['sessionStore'], 'readonly');
        const store = transaction.objectStore('sessionStore');
        const getRequest = store.get('sessionId');

        getRequest.onsuccess = () => {
            resolve(getRequest.result ? getRequest.result.value : null);
        };

        getRequest.onerror = (event: any) => {
            console.error('Error retrieving session ID:', event.target.error);
            reject(new Error('Error retrieving session ID'));
        };
    });
}

function generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < array.length; i++) {
        result += characters.charAt(array[i] % charactersLength);
    }
    return result;
}

export async function retrieveSessionAndCorrelationID(IDlength: number = 5): Promise<{ correlationID: string, sessionID: string }> {
    await initializeDatabase(); // Ensuring database is initialized before proceeding
    let sessionID = await getSessionId();
    
    if (!sessionID) {
        sessionID = generateRandomString(IDlength);
        await saveSessionId(sessionID);
    }
    let staleCorrelationID = sessionStorage.getItem("correlationID");
    if(staleCorrelationID){
        let correlationID = staleCorrelationID;
        return { correlationID, sessionID };
    }
    // Generate a new correlation ID (e.g., a timestamp
    const correlationID = `${generateRandomString(IDlength)}-${Date.now()}`;
    sessionStorage.setItem("correlationID", correlationID);
    return { correlationID, sessionID };
}

// Optional: Delete the database manually (for testing or development purposes)
export function deleteDatabase() {
    const request = indexedDB.deleteDatabase('sessionDB');
    sessionStorage.removeItem("correlationID");
    
    request.onsuccess = () => {
        console.log('Database deleted successfully');
    };

    request.onerror = (event: any) => {
        console.error('Error deleting database:', event.target.error);
    };
}
