import { REGEX } from "../constants/regex";
import { hmsProgressBarUpdate } from "../util/share";
import { GiftConfirmation } from "./gift-confirmation";
import { GiftPurchase } from "./gift-purchase";
declare global {
    interface Window {
        giftRecipient?: Record<string, any>;
    }
}
export class GiftRecipient {
    private maxCertificates: number = 5;
    private certificateCount: number = 1;
    private maxMessageLength: number = 500;
    private greetingMessageLength:number = 100;
    constructor() {
        this.init();
    }

    private init() {
        this.addNextBtnEventListener();
        this.addGiftRecipientEventListener();
        this.addRemoveCertificateEventListener();
        this.addInputEventListeners();
        this.backBtn();
        this.initializeInputRestriction();
    }

    private addNextBtnEventListener() {

        const nextButton = this.removeAllEventListeners('.gift-certificate-form .btn-next');
        if (nextButton) {
            nextButton.addEventListener('click', this.handleNextButtonClick.bind(this));
        }
    }

    private backBtn() {

        const backButton = this.removeAllEventListeners('.gift-certificate-form .cancel-verification');
        if (backButton) {
            backButton.addEventListener('click', this.handleBackButtonClick.bind(this));
        }
    }

    private handleBackButtonClick() {
        if (document.referrer) {
            window.history.back();
        } else {
            window.location.href = window.location.origin;
        }
    }

    private addGiftRecipientEventListener() {
        const addCertificateButton = this.removeAllEventListeners('.add-certificate');

        if (addCertificateButton) {
            addCertificateButton.addEventListener('click', this.handleAddCertificateClick.bind(this));
        }

    }

    private handleAddCertificateClick(event: any): void {
        event.preventDefault();
        const giftCertificateWraps = document.querySelectorAll('.gift-add-certificate-form');
        const index = (giftCertificateWraps.length + 1);

        if (this.certificateCount < this.maxCertificates) {
            // Clone the gift certificate section 5 times
            this.cloneGiftCertificate(index);
            this.certificateCount++;
            // Update event listeners again for the new cloned elements
            // updateAddCertificateEventListener();
            this.addInputEventListeners();
            this.initializeInputRestriction();

        } else {
            alert("Maximum of 5 gift certificates reached.");
        }
    }

    private handleRemoveCertificateClick(event: any): void {
        event.preventDefault();
        const certificateWraps = document.querySelectorAll('.gift-add-certificate-form');
        if (certificateWraps.length > 1) {
            const wrapToRemove = certificateWraps[certificateWraps.length - 1];
            wrapToRemove.parentNode?.removeChild(wrapToRemove);
            this.certificateCount--;
            this.updateEventListeners();
        }

        if (certificateWraps.length === 2) {
            const removeCertificateButton = document.querySelector('.remove-certificate');
            if (removeCertificateButton) {
                removeCertificateButton.classList.add('hidden');
            }
        }
    }

    private updateEventListeners() {
        this.addGiftRecipientEventListener();
        this.addRemoveCertificateEventListener();
    }

    private addRemoveCertificateEventListener() {
        const removeCertificateButton = this.removeAllEventListeners('.remove-certificate');

        if (removeCertificateButton) {
            removeCertificateButton?.addEventListener('click', this.handleRemoveCertificateClick.bind(this));
        }
    }

    private cloneGiftCertificate(index: number) {
        const giftCertificateWrapAll = document.querySelectorAll('.gift-add-certificate-form');
        const giftCertificateWrap = giftCertificateWrapAll.length > 0 ? giftCertificateWrapAll[giftCertificateWrapAll.length - 1] : null;

        if (giftCertificateWrap?.parentNode) {
            const clone = giftCertificateWrap?.cloneNode(true) as HTMLElement;
            const howToWorksTooltip = clone.querySelector('.how-works-text') as HTMLElement;
            if(howToWorksTooltip?.parentNode){
                const parentElement = howToWorksTooltip.parentNode as HTMLElement;
                parentElement.style.display = 'none';
            }

            const inputs = clone.querySelectorAll('input, textarea');           
            if (inputs.length > 0){
                const firstErrorField = inputs[0] as HTMLInputElement;
                if (firstErrorField) {
                    firstErrorField?.focus();
                }
            }
           
            inputs.forEach((input:any) => {
                const name = input.getAttribute('id')?.replace(/-\d+$/, ''); // Ensure proper replacement of trailing number

                if (name) {
                    const newName = `${name}-${index}`;


                    // Find the associated error message span by input's id
                    const errorMsgSpan = clone.querySelector(`#${input.id}-error-msg`) as HTMLElement;
                    if (errorMsgSpan) {
                        errorMsgSpan.setAttribute('id', `${newName}-error-msg`);
                        errorMsgSpan.classList.add('hidden');
                        errorMsgSpan.setAttribute('for', `${newName}`);
                    }


                    input.setAttribute('name', newName);
                    input.setAttribute('id', newName);

                    if (input?.value) {
                        input.value = '';
                    }
                }
            });

            giftCertificateWrap.parentNode.insertBefore(clone, giftCertificateWrap.nextSibling);
        }

        if (index > 1) {
            const removeCertificateButton = document.querySelector('.remove-certificate');
            if (removeCertificateButton) {
                removeCertificateButton.classList.remove('hidden');
            }
        }
    }



    removeAllEventListeners(selector: any) {
        const element = document.querySelector(selector);
        if (element) {
            const newElement = element.cloneNode(true);
            element.parentNode.replaceChild(newElement, element);
            return newElement;
        }
        return null;
    }

    private handleNextButtonClick() {
        if (this.isValidForm()) {
            const data = this.collectData();
            window.giftRecipient = data || [];
            console.log(data, "........Data Loading........");
            this.switchToNextStep();
        }
    }

    private switchToNextStep() {
        const step1 = document.getElementById("gc-recipients-details");
        const step2 = document.getElementById("gc-purchaser-details");
        hmsProgressBarUpdate('100%', 1);
        window.scrollTo(0,0);

        if (step1 && step2) {
            step1.classList.remove('form-step-active');
            step2.classList.add('form-step-active');
        }
        console.log("Valid Form:");
    }

    private collectData(): Record<string, any>[] {
        const giftCertificateWraps = document.querySelectorAll('.gift-add-certificate-form');
        const data: Record<string, any>[] = [];

        giftCertificateWraps.forEach((wrap, index) => {
            index = index + 1;
            const amountInput = document.getElementById(`gc-amount-${index}`) as HTMLInputElement;
            const currencySign = '$';
            const amountValue = amountInput?.value.replace(currencySign, '')
            const firstNameInput = document.getElementById(`recipient-first-name-${index}`) as HTMLInputElement;
            const lastNameInput = document.getElementById(`recipient-last-name-${index}`) as HTMLInputElement;
            const emailInput = document.getElementById(`recipient-email-${index}`) as HTMLInputElement;
            const greetingInput = document.getElementById(`recipient-greeting-${index}`) as HTMLInputElement;
            const messageInput = document.getElementById(`recipient-message-${index}`) as HTMLInputElement;
            const confirmEmailInput = document.getElementById(`recipient-confirm-email-${index}`) as HTMLInputElement;
            const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
            const firstName = firstNameInput?.value ?? '';
            const lastName = lastNameInput?.value ?? '';


            data.push({
                ConceptId:conceptId,
                Amount: amountValue ?? '',
                RecipientFirstName: firstName,
                RecipientLastName: lastName,
                RecipientEmail: emailInput?.value ?? '',
                confirmEmail: confirmEmailInput?.value ?? '',
                PersonalTitle: greetingInput?.value ?? '',
                PersonalMessage: messageInput?.value ?? '',
                RecipientFullName:firstName + ' '+lastName,
            });
        });

        return data;
    }

    private isValidForm() {

        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));

        const errors: Record<string, string> = {};
        const giftCertificateWraps = document.querySelectorAll('.gift-add-certificate-form');

        giftCertificateWraps.forEach((wrap, index) => {
            index = index + 1;
            const amountInput = document.getElementById(`gc-amount-${index}`) as HTMLInputElement;
            const firstNameInput = document.getElementById(`recipient-first-name-${index}`) as HTMLInputElement;
            const lastNameInput = document.getElementById(`recipient-last-name-${index}`) as HTMLInputElement;
            const emailInput = document.getElementById(`recipient-email-${index}`) as HTMLInputElement;
            const greetingInput = document.getElementById(`recipient-greeting-${index}`) as HTMLTextAreaElement;
            const messageInput = document.getElementById(`recipient-message-${index}`) as HTMLTextAreaElement;
            const confirmEmailInput = document.getElementById(`recipient-confirm-email-${index}`) as HTMLInputElement;

            // Validate amount input
            if (amountInput?.required && !amountInput.value) {
                const errorMessage = document.getElementById(`${amountInput.id}-error-msg`)?.innerText ?? 'Please enter a valid amount';
                errors[amountInput.id] = errorMessage;
            }
            const maxamount = (document.getElementById('maxamount') as HTMLInputElement)?.value;
            const minamount = (document.getElementById('minamount') as HTMLInputElement)?.value;
            const currencySign = '$';
            const amountValue = parseFloat(amountInput.value.replace(currencySign, ''));
            const fieldValue = amountInput.value.replace(currencySign, '')

            if (amountInput?.required && !REGEX.sendNumber.test(fieldValue)) {
                errors[amountInput.id] = 'Please enter a valid amount';
            } else if (Number(amountValue) < Number(minamount) || Number(amountValue) > Number(maxamount)) {
                errors[amountInput.id] = `Please enter an amount between ${minamount} and ${maxamount}`;
            }

            // Validate first name input
            if (firstNameInput?.required && !firstNameInput.value) {
                const errorMessage = document.getElementById(`${firstNameInput.id}-error-msg`)?.innerText ?? 'Please enter a valid first name';
                errors[firstNameInput.id] = errorMessage;
            } else if (firstNameInput && firstNameInput?.required && !REGEX.sendName.test(firstNameInput.value)) {
                errors[firstNameInput.id] = "Please enter a valid first name";
            }

            // Validate last name input
            if (lastNameInput?.required && !lastNameInput.value) {
                const errorMessage = document.getElementById(`${lastNameInput.id}-error-msg`)?.innerText ?? 'Please enter a valid last name';
                errors[lastNameInput.id] = errorMessage;
            } else if (lastNameInput && lastNameInput?.required && !REGEX.sendName.test(lastNameInput.value)) {
                errors[lastNameInput.id] = "Please enter a valid last name";
            }

            // Validate email input
            if (emailInput?.required && !emailInput.value) {
                const errorMessage = document.getElementById(`${emailInput.id}-error-msg`)?.innerText ?? 'Please enter a valid email address';
                errors[emailInput.id] = errorMessage;
            }

            // Validate email format
            if (emailInput?.value && !REGEX.sendEmail.test(emailInput.value)) {
                const errorMessage = 'Please enter a valid email address';
                errors[emailInput.id] = errorMessage;
            }

            // Validate confirm email input
            if (confirmEmailInput?.required && !confirmEmailInput.value) {
                const errorMessage = document.getElementById(`${confirmEmailInput.id}-error-msg`)?.innerText ?? 'Please confirm the email address';
                errors[confirmEmailInput.id] = errorMessage;
            }

            // Validate email match
            if (emailInput && confirmEmailInput && emailInput.value !== confirmEmailInput.value) {
                const errorMessage = 'Email addresses do not match';
                errors[confirmEmailInput.id] = errorMessage;
            }

            // Validate greeting input
            if (greetingInput?.required && !greetingInput.value) {
                const errorMessage = document.getElementById(`${greetingInput.id}-error-msg`)?.innerText ?? 'Please enter a greeting';
                errors[greetingInput.id] = errorMessage;
            }

            if(greetingInput && greetingInput?.value.length > this.greetingMessageLength){
                errors[greetingInput?.id] = `Greeting cannot exceed ${this.greetingMessageLength} characters`;
            }

            // Validate message input
            if (messageInput?.required && !messageInput.value) {
                const errorMessage = document.getElementById(`${messageInput.id}-error-msg`)?.innerText ?? 'Please enter a message';
                errors[messageInput.id] = errorMessage;
            }

            if(messageInput && messageInput?.value.length > this.maxMessageLength){
                errors[messageInput?.id] = `Recipient Message cannot exceed ${this.maxMessageLength} characters`;
            }
        });

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }

        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        return Object.keys(errors).length === 0;
    }

    private addInputEventListeners() {
        const inputs = document.querySelectorAll('#gc-recipients-details input, textarea');
        inputs.forEach((input: any) => {
            input?.addEventListener('input', this.handleInputEvent.bind(this, input));
        });
    }

    private handleInputEvent(input: HTMLInputElement | HTMLTextAreaElement) {
        const errors: Record<string, string> = {};
        if (input.name.includes('amount')) {
             this.validateAmount(input, errors);
        }
        else if (input.name.includes('first-name')){
            this.validateFirstName(input, errors);
        }
        else if(input.name.includes('last-name')) {
            this.validateLastName(input, errors);
        } else if (input.name.includes('email')) {
            if (input.name.includes('confirm')) {
                this.validateConfirmEmail(input, errors);
            } else {
                this.validateEmail(input, errors);
            }
        }
        // else {
        //     this.validateNonEmpty(input, errors);
        // }
        if (Object.keys(errors).length > 0) {
            this.showErrors(errors);
        }
        if (!errors[input.id]) {
            this.clearFieldError(input?.id);
        }
    }
    private clearFieldError(fieldId: string) {
        const field = document.getElementById(fieldId);
        const fieldError = document.getElementById(`${fieldId}-error-msg`);
        if (field) {
            field.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    private validateLastName(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendName.test(field.value)) {
            const errorMessage = `Please enter a valid last name`;
            errors[field.id] = errorMessage;
        }
    }
    private validateFirstName(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendName.test(field.value)) {
            const errorMessage = `Please enter a valid first name`;
            errors[field.id] = errorMessage;
        }
    }

    private validateEmail(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        if (!field.value || !REGEX.sendEmail.test(field.value)) {
            const errorMessage = 'Please enter a valid email address';
            errors[field.id] = errorMessage;
        }
    }

    private validateConfirmEmail(field: any, errors: Record<string, string>) {
        if(field?.value ==''){
            this.hideError(field?.id);
            return
        }
        const emailField = document.getElementById(field.id.replace('confirm-', '')) as HTMLInputElement;
        if (!field.value || field.value !== emailField.value) {
            const errorMessage = 'Email addresses do not match';
            errors[field.id] = errorMessage;
        }
    }

    // private validateNonEmpty(field: HTMLInputElement | HTMLTextAreaElement, errors: Record<string, string>) {
    //     if (!field.value) {
    //         const errorMessage = `Please enter a valid ${field.placeholder.toLowerCase()}`;
    //         errors[field.id] = errorMessage;
    //     }
    // }


    private validateAmount(field: any, errors: Record<string, string>, initializeAmountValid?: boolean) {
        const currencySign = '$';
        const minamount = Number((document.getElementById('minamount') as HTMLInputElement)?.value || 0);
        const maxamount = Number((document.getElementById('maxamount') as HTMLInputElement)?.value || Infinity);
        const value = parseFloat(field.value.replace(currencySign, ''));
        if (initializeAmountValid) {
           // const value = parseFloat(field.value.replace(currencySign, ''));
            const errorMsg = field.closest('.gift-certificate-wrap')?.querySelector('.error-msg') as HTMLElement;
            if (isNaN(value) || value < minamount || value > maxamount) {
                field.classList.add('invalid-field');
                if (errorMsg) {
                    errorMsg.textContent = `Please enter an amount between ${minamount} and ${maxamount}`;
                    errorMsg.classList.remove('hidden');
                }
            } else {
                field.classList.remove('invalid-field');
                if (errorMsg) {
                    errorMsg.classList.add('hidden');
                }
            }
            initializeAmountValid = false;
        }

        else {
            const fieldValue = field.value.replace(currencySign, '')

            if (field?.value == '') {
                this.hideError(field?.id);
                return
            }
            if (!field.value || !REGEX.floatDigit.test(fieldValue) || value < minamount || value > maxamount) {
                const errorMessage = `Please enter an amount between ${minamount} and ${maxamount}`;
                errors[field.id] = errorMessage;
            }
            this.initializeAmountInputs(field);
        }
    }


    hideError(id: any) {
        const fieldError: any = document.getElementById(`${id}-error-msg`);
        fieldError.classList?.add('hidden');
    }

    private showErrors(errors: Record<string, string>) {
        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });

        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }
    }

    private initializeInputRestriction(): void {
        const inputs = document.querySelectorAll<HTMLInputElement>('input[id^="gc-amount-"]');

        inputs.forEach(input => {
            input.addEventListener('input', this.handleInputChange.bind(this));
        });
    }

    private handleInputChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        const inputValue = target.value;
        target.value = inputValue.replace(/[^\d.]/g, '');
        if (target.id.startsWith('gc-amount')) {
            const value = target.value.replace(/[^\d]/g, '');
            target.value = value ? '$' + value : '';
            this.validateAmount(target, {});
        }
    }

     InlineValidateAmount(input: any) {
        const currencySign = '$';
        const minAmount = Number((document.getElementById('minamount') as HTMLInputElement)?.value || 0);
        const maxAmount = Number((document.getElementById('maxamount') as HTMLInputElement)?.value || Infinity);
        const value = parseFloat(input.value.replace(currencySign, ''));
        const errorMsg = input.closest('.gift-certificate-wrap')?.querySelector('.error-msg') as HTMLElement;
        if (isNaN(value) || value < minAmount || value > maxAmount) {
            input.classList.add('invalid-field');
            if (errorMsg) {
                errorMsg.textContent = `Please enter an amount between ${minAmount} and ${maxAmount}`;
                errorMsg.classList.remove('hidden');
            }
        } else {
            input.classList.remove('invalid-field');
            if (errorMsg) {
                errorMsg.classList.add('hidden');
            }
        }
    }
  initializeAmountInputs(input: any) {
        const currencySign = '$';
        if (input.id.startsWith('gc-amount')) {
            let initializeAmountValid = true;
            this.validateAmount(input,{}, initializeAmountValid);
            input.addEventListener('focus', function () {
                if (!input.value.includes(currencySign)) {
                    input.value = currencySign + input.value;
                }
            });
    
            input.addEventListener('keydown',  (e: any) => {
                if (!/\d/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                    this.validateAmount(input,{}, initializeAmountValid);
                    e.preventDefault();
                }else if(e.key === 'Backspace' || e.key === 'Delete') {
                    if (input.value.length <= 2) {
                        e.preventDefault();
                    }
                }
            })
            input.addEventListener('keyup',  (e: any) => {
                if (!/\d/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                    this.validateAmount(input,{}, initializeAmountValid);
                    e.preventDefault();               
                }
                else if (e.key.length === 1 && /\d/.test(e.key)) {
                    const numericValue = input.value.replace(/[^0-9]/g, '');
                    input.value = numericValue ? currencySign + numericValue : '';
                    this.validateAmount(input,{}, initializeAmountValid);
                    e.preventDefault();
                }
                else if(e.key === 'Backspace' || e.key === 'Delete') {
                    if (input.value.length <= 2) {
                        e.preventDefault();
                    }
                    else if (input.value.length > 2) {
                        this.validateAmount(input,{}, initializeAmountValid);
                        e.preventDefault();
                    }

                }
            })
        }
    
    }
    
}
document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId == 1) {
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams.entries());
        const resultMsg = params.result_msg;
        const isCertification  = currentUrl.includes("gift-certificate"); //params?.ssl_description?.includes('GiftCertificateGroup')

        if (window.location.pathname.endsWith("confirmation/") && isCertification) {
            const giftConfirmation = new GiftConfirmation();

            if (currentUrl.indexOf("?") !== -1) {


                window.localStorage.setItem('giftCertPaymentResult', resultMsg);
                if (resultMsg?.toUpperCase() === "APPROVAL") {
                    giftConfirmation.displayMessage(true);
                }else{
                    giftConfirmation.displayMessage(false);
                }
                giftConfirmation.pushHTMLToHistoryAndCleanUrl();

            } else {
                const storedResultMsg = window.localStorage.getItem('giftCertPaymentResult');
                if (storedResultMsg) {
                    giftConfirmation.displayMessage(true);
                }
            }

            window.onpopstate = (event: PopStateEvent) => {
                if (event.state) {
                    document.documentElement.outerHTML = event.state.html;
                    document.title = event.state.pageTitle;
                }
            };
        }else{
            new GiftRecipient();
            new GiftPurchase();
        }

        // END After payment Confirmation page 
    }
});