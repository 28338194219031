import { stopLoader } from "../util/loader";
import { setTimeAndDate } from "../util/setDateAndTime";

export function validEstimate(){
    const estimate = sessionStorage.getItem('mollyEstimate');
    const sessionFlag = sessionStorage.getItem("noCalendarPricing");
    if(sessionFlag){
        return false;
    }
    let flag;
    if(estimate && estimate!== "" && estimate!== "null" && estimate!= undefined){
        flag = true;
    }
    else{
        flag = false;
    }
    return flag;
}

export class Confirmation{
    private recurHeading: HTMLElement;
    private oneTimeHeading: HTMLElement;
    private leadHeading: HTMLElement;
    private osHeading: HTMLElement;
    private appointmentDetails: HTMLElement;
    private inputData: any;
    private frequency: string;
    private type: string;
    constructor(){
        this.recurHeading = document.getElementById('recurring_heading') as HTMLElement;
        this.oneTimeHeading = document.getElementById('onetime_heading') as HTMLElement;
        this.osHeading = document.getElementById('appointment_title') as HTMLElement;
        this.leadHeading = document.getElementById('leadflow_title') as HTMLElement;
        this.appointmentDetails = document.querySelector('ul.appointment-list') as HTMLElement;
        this.inputData = JSON.parse(sessionStorage.getItem('rl-post-data') as string);
        this.frequency = sessionStorage.getItem('JobFrequency') as string;
        this.type = sessionStorage.getItem('CustomerType') as string;
    }
    showHeading(){
        // Check whether one time or recur
        if(this.frequency.toLowerCase().includes('recurring')){
            this.recurHeading.classList.remove('hidden');
            this.oneTimeHeading.classList.add('hidden');
        }
        else{
            this.recurHeading.classList.add('hidden');
            this.oneTimeHeading.classList.remove('hidden');
        }
    }
    showAppointmentHeading(){
        if(this.inputData.IsLeadOnly){
            this.leadHeading.classList.remove('hidden');
            this.osHeading.classList.add('hidden');
        }
        else{
            this.leadHeading.classList.add('hidden');
            this.osHeading.classList.remove('hidden');
        }
    }
    updateAppointmentDetails(){
        let flag = this.frequency.toLowerCase();
        const arrs:any = {
            "Date and Time": '/brand/_assets/images/brand-img/lead-flow/calendar.svg',
            "Estimate": '/brand/_assets/images/brand-img/lead-flow/tag.svg',
            "Service Address": '/brand/_assets/images/brand-img/lead-flow/location-marker.svg',
            "Contact Info": '/brand/_assets/images/brand-img/lead-flow/mail.svg',
        };
        Object.keys(arrs).forEach((a:string)=>{
            const html:string = this.generateLi(a, arrs[a] ,flag);
            this.appointmentDetails.innerHTML = this.appointmentDetails.innerHTML.concat(html);
        });
    }
    generateLi(category:string, img:string, flag:string) : string{
        let text1, text2, span, mobile = '';
        let html = ``;
        if(category.includes('Estimate') && !flag.includes('recurring') && !this.type.toLowerCase().includes("commercial") && validEstimate()){
            const estimateData = JSON.parse(sessionStorage.getItem('mollyEstimate') as string);
            const oneTimeClean = this.inputData.JobFrequencyDetail?.toLowerCase().includes('not a move in or move out clean');
            const dateDay = sessionStorage.getItem('selectedDay');
            if(oneTimeClean){
                if(dateDay === 'Saturday'){
                    text1 = `$${estimateData?.estimateOccasionalRangeLowSaturday} - $${estimateData?.estimateOccasionalRangeHighSaturday}`;
                }
                else{
                    text1 = `$${estimateData?.estimateOccasionalRangeLow} - $${estimateData?.estimateOccasionalRangeHigh}`;
                }
            }
            else{
                if(dateDay === 'Saturday'){
                    text1 = `$${estimateData?.estimateMoveRangeLowSaturday} - $${estimateData?.estimateMoveRangeHighSaturday}`;
                }
                else{
                    text1 = `$${estimateData?.estimateMoveRangeLow} - $${estimateData?.estimateMoveRangeHigh}`;
                }
            }
            if(text1 == null || text1 == undefined){
                return '';
            }
            span = 'yes';
        }
        else if(category.includes('Date and Time') && !this.inputData.IsLeadOnly){
            let obj = this.processString(sessionStorage.getItem('userSelectDatae') as any);
            text1 = obj.date;
            if(flag.includes('recurring')){
                text2 = obj.time; 
            }
            else{
                text2 = sessionStorage.getItem('timeOfDay');
            }
            
        }
        else if(category.includes('Service Address')){
            const suggestFlag = sessionStorage.getItem("suggestion");
            const suggestAddr = sessionStorage.getItem("suggestedAddress") as string;
            if(suggestFlag && suggestFlag === "true" && suggestAddr){                
                const suggestJSON = JSON.parse(suggestAddr);
                if(suggestJSON.addr1 === "No Address Collected"){
                    text1 = this.inputData.Address2 ? this.inputData.Address2 : '';
                }
                else{
                    const addressParts1 = [
                        suggestJSON.addr1 ? `${suggestJSON.addr1}` : this.inputData.Address,
                        this.inputData.Address2 ? `${this.inputData.Address2}` : '',
                    ];
                    text1 = addressParts1.join(", ");
                }
                const addressParts2 = [
                    suggestJSON.city ? `${suggestJSON.city}` : this.inputData.City,
                    suggestJSON.stateCode ? `${suggestJSON.stateCode}` : this.inputData.State,
                    suggestJSON.postalCode ? `${suggestJSON.postalCode}` : this.inputData.PostalCode
                ];
                if(!text1){
                    text1 = addressParts2.join(", ");
                }
                else{
                    text2 = addressParts2.join(", "); 
                }   
            }
            else if(suggestFlag === "false"){
                const addressParts1 = [
                    this.inputData.Address,
                    this.inputData.Address2 ? `${this.inputData.Address2}` : '',
                ];
                text1 = addressParts1.join(", ");
                text2 = `${this.inputData.City}, ${this.inputData.State}, ${this.inputData.PostalCode}`;
            }
            sessionStorage.setItem("suggestion","false");  
        }
        else if(category.includes('Contact Info')){
            text1 = this.inputData.FirstName + " " + this.inputData.LastName;
            text2 = this.inputData.Email;
            mobile = this.inputData.Phone;
        }

        if(text1 && img){
            html = html.concat(`<li class="appointment-list-item"> <div class="icon-block">`);
            html = html.concat(`<img src="${img}" alt="calendar" width="24" height="24">`);
            html = html.concat(`<div class="appointment-detail-wrap"><div class="icon-block-wrap"><p class="icon-block-heading">${category}</p><p class="icon-block-text">${text1}</p>`);
        }
        if(text2){
            html = html.concat(`<p class="icon-block-text">${text2}</p>`);
        }
        if(span){
            html = html.concat(`<span class="icon-block-sub-text">plus any applicable sales taxes</span>`);
        }
        if(mobile){
            html = html.concat(`<p class="icon-block-text">${mobile}</p>`);
        }
        html = html.concat(`</div>`);
        const tag = sessionStorage.getItem("hidePreferMethod");
        if(category.includes('Contact Info') && tag==="false"){
            html = html.concat(`<div class="icon-block-wrap"><p class="icon-block-heading">Preferred Method of contact:</p><p class="icon-block-text">${sessionStorage.getItem('PreferredCommunicationText')}</p></div>`);
        }
        html = html.concat(`</div> </div> </li>`);
        return html;
    }
    processString(str: string): any {
        let arr = str.split(",");
        let dateStr = arr.slice(0,arr.length-1).join(",").trim();
        let timeStr = arr[arr.length-1].trim();
        let obj = {
            'date': `${setTimeAndDate(dateStr)}`, 
            'time': `${timeStr.toLowerCase()}`
        }
        return obj;
    }
   
}

document.addEventListener("DOMContentLoaded", () => {
    stopLoader();
    const conceptCode: any = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const divTag: any = document.getElementsByClassName('mly-confirmation-section');
    if (conceptCode === "MLY" && divTag?.length>0) {
        const c = new Confirmation();
        c.showHeading();
        c.showAppointmentHeading();
        c.updateAppointmentDetails();
    }

});