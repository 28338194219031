import { apiRequestForWrapper } from "../api/apiRequest";

const CACHE_NAME = "opus2.0-api-cache";
const displayCacheLog = (process.env.SHOW_CACHE_LOG && typeof JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase()) === 'boolean') ? JSON.parse(process.env.SHOW_CACHE_LOG.toLowerCase()) : false;

//fetching data from API
async function fetchDataFromAPI(url: string) {
  try {
    const response = await apiRequestForWrapper({ method: "GET", url });
    return response;
  } catch (error) {
    const errorResponse = handleApiError(error);
    if (errorResponse) {
      throw errorResponse;
    }
    console.error("Error fetching data from API:", error);
    throw error;
  }
}

function handleApiError(error: any) {
  if(error instanceof Error && error.message === 'Full address is needed to narrow down results'){
    return new Error('Full address is needed to narrow down results');
  }
  return error;
}
//Function to check to trim whitespaces from the zipcode, address and check if api key is missing
function validateUrl(url: string, apiKeyNeeded:boolean) {
  try {
    const urlObj = new URL(url);
    const urlParams = urlObj.searchParams;
    const postalCode = urlParams.get('PostalCode');
    const address = urlParams.get('Address');
    // Trim whitespaces from the PostalCode parameter
    if (postalCode) {
      urlParams.set('PostalCode', postalCode.trim());
    }
    // Trim whitespaces from the Address parameter
    if (address) {
      urlParams.set('Address', address.trim());
    }

    if(apiKeyNeeded){
      // Check if the apikey parameter is present
      let apiKey = null;
      urlParams.forEach((value, key) => {
        if (key.toLowerCase() === 'apikey') {
          apiKey = value;
        }
      });

      if (!apiKey) {
        console.error("API key is missing in the URL");
        return null;
      }
    }
    

    return urlObj.toString();
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

//Function to fetch the cached data
async function getCachedData(url: string, cacheExpiry:number) {
  const cache = await caches.open(CACHE_NAME);
  const response = await cache.match(url);
  if (!response) {
    return null;
  }
  const cachedData = await response.json();
  const isCacheValid = Date.now() - cachedData.timestamp < cacheExpiry * 60 * 1000;

  if (isCacheValid) {
    if(displayCacheLog)
      console.info(`C_Hit: ${url}`);
    return cachedData.data;
  }
  await cache.delete(url);
  return null;
}

//Function to set the cached data
async function setCacheData(url: string, freshData: any) {
  const cache = await caches.open(CACHE_NAME);
  const cacheData = { timestamp: Date.now(), data: freshData };
  cache.put(url, new Response(JSON.stringify(cacheData)));
  if(displayCacheLog)
    console.log("C_Set ", url)
}

//Final function exported to all pages for all get requests.
export async function getCachedAPIResponse(url: string, duration = 10, apiKeyNeeded = true, cacheResult = true ) {
  const validatedUrl = validateUrl(url, apiKeyNeeded);
  if (!validatedUrl) {
    return null;
  }
  
  if (cacheResult) {
    // Check if caching is supported in the browser
    if (!("caches" in window)) {
      console.warn("Caching not present in this browser");
      return fetchDataFromAPI(validatedUrl);
    }

    try {
      const cachedAPIResponse = await getCachedData(validatedUrl, duration);
      if (cachedAPIResponse) {
        return cachedAPIResponse;
      }

      const freshData = await fetchDataFromAPI(validatedUrl);
      if (freshData) {
        await setCacheData(validatedUrl, freshData);
      }
      return freshData;
    } catch (error) {
      console.error("Error with Cache API:", error);
      throw error; // Propagate the error to the caller
    }
  } else {
    return fetchDataFromAPI(validatedUrl);
  }
}

