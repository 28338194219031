import apiRequest from "../api/apiRequest";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";
import { retrieveSessionAndCorrelationID } from "../util/sessionCorelationID";
import { getBrandDetailsFromBrandJson, storeBookingApiPayload } from "../util/share";
import { isTestFcn } from "./booking-payload";
import { throwBookingError } from "./molly-flow";
let moment = require('moment');

export function configureNotesComments():boolean{
    let flag = false;
    return flag;
}

function payloadHelper(bookingPayload:any){
    let noteText = `Customer Type: ${bookingPayload.CustomerType}, MLY Frequency: ${bookingPayload.JobFrequency}` as string;
    if(bookingPayload.JobFrequency.toLowerCase().includes("one-time")){
        noteText = noteText.concat(`, ${bookingPayload.JobFrequencyDetail}`);
    }
    if(bookingPayload.EstimateSquareFeet && bookingPayload.EstimateBathrooms){
        if(bookingPayload.CustomerType.toLowerCase().includes("residential")){
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.EstimateSquareFeet}}, Bedrooms: {${bookingPayload.EstimateBedrooms}} , Bathrooms: {${bookingPayload.EstimateBathrooms}}`); 
        }
        else{
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.EstimateSquareFeet}}, Offices: {${bookingPayload.EstimateBedrooms}} , Bathrooms: {${bookingPayload.EstimateBathrooms}}`);
        }
    }
    const preference = sessionStorage.getItem('PreferredCommunicationText') as string;
    if(preference){
        if(preference?.toLowerCase().includes("text")){
            noteText = noteText.concat(`, Text OptIn: Yes`);
        }
    }
    bookingPayload.Note = bookingPayload.Note.concat(noteText);
    if(configureNotesComments()){
        bookingPayload.Comments = bookingPayload.Comments.concat(noteText);
    }
    
    return bookingPayload;
}

export async function callBookingApi(bookingPayload: any, browerCloseCheck?: boolean){
    startLoader();
    bookingPayload = payloadHelper(bookingPayload);
    
    let sessionID: string | undefined;
    let correlationID: string | undefined;

    async function getSessionAndCorrelationID() {
        try {
            await new Promise<void>((resolve, reject) => {
                retrieveSessionAndCorrelationID(5).then((ids) => {
                    if (ids) {
                        correlationID = ids.correlationID;
                        sessionID = ids.sessionID;
                        resolve();
                    } else {
                        reject(new Error("No IDs returned"));
                    }
                });
            });
        } catch (error) {
            console.log("error in getting session and correlation ID", error);
        }
    }

    await getSessionAndCorrelationID();


    let url = '';
    if(correlationID && sessionID){
        url = `${apiConfig.BOOKING_API_URL}&correlationId=${correlationID}&sessionId=${sessionID}`
    }
    else{
        url = apiConfig.BOOKING_API_URL;
    }
    
    const request = {
        method: 'POST',
        url: url,
        data: bookingPayload
    };
    apiRequest(request)
        .then((response: any) => {
            if(response){
                storeBookingApiPayload(bookingPayload, response);
                window.history.replaceState({}, "" , window.location.href);
                if(browerCloseCheck) {
                    sessionStorage.setItem('IsLeadOnly','false');
                    stopLoader();
                } 
                if(!browerCloseCheck) {
                    document.querySelectorAll("form").forEach((f)=>{
                        f.reset();
                    });
                    window.location.pathname = '/confirmation';
                }
            }
            else{
                throwBookingError();
            }
            
        })
        .catch((error:any) => {
            throwBookingError();
        })
}

export async function getFranchiseDetails(addressParam:string) : Promise<any> {
    const url:any = apiConfig.LocateLocationApiWithRoundRobinFalse.replace('sAddressParamValue', encodeURIComponent(addressParam));
    const result:any = getCachedAPIResponse(url);
    return result;
}

export function determineFlowType(WebLocationId:number) : any {
    // Function to determine whether OS flow or Lead flow
    const url = `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${WebLocationId}`;
    return getCachedAPIResponse(url);

}

export async function checkTechAvailability(arg:string) : Promise<any> {
    const weblocationId = sessionStorage.getItem('franchiseWebLocationId');
    const zipValue = sessionStorage.getItem('zipcode');
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const maxWeeks = brandData?.calendarMaxWeeksCap ? brandData?.calendarMaxWeeksCap: 8;
    let estFlag;
    if(arg.toLowerCase().includes('recurring')){
        estFlag = true;
    }else{
        estFlag = false;
    }
    const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=${7*maxWeeks}&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=${estFlag}&apikey=${process.env.JS_API_KEY}`;
    return getCachedAPIResponse(dynamic_url);
    
}

export async function configureMollyEstimate() : Promise<boolean>{
// This method will call an API to confirm whether the estimate needs to be shown in the calendar screen or not.
    let locationID = sessionStorage.getItem('franchiseWebLocationId') ? sessionStorage.getItem('franchiseWebLocationId') : localStorage.getItem('weblocationId');
    const val = await determineFlowType(Number(locationID));
    const returnVal = val?.options?.mollyOneTimeCleansDisableEstimatePricing;
    return (returnVal? returnVal: true);
    
}

export async function confirmMlyLeadSourceAPI() : Promise<string>{
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let endpoint:string = apiConfig.GenericLeadSourceEndpoint;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    if(brandData){
        if(brandData?.send_brand_leadsource_api){
            endpoint = apiConfig.MollyLeadSourceEndpoint;
        }
        else{
            endpoint = apiConfig.GenericLeadSourceEndpoint;
        }
    }
    return endpoint;
    
}

export async function setDefaultLeadSource(): Promise<any> {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const obj = {
        "LeadSourceID": brandData?.default_leadSourceID,
        "LeadSource": brandData?.default_leadSource
    }
    return obj;
}

export async function calcEstimate(bookingPayload:any){
    startLoader();
    let payload ={
        "franchiseWebLocationId": bookingPayload.WebLocationId,
        "LeadSourceId": bookingPayload.LeadSourceId,
        "SecondaryLeadSourceId": 0,
        "Note": bookingPayload.Note,
        "EstimateTitle": "null",
        "FirstName": bookingPayload.FirstName,
        "LastName": bookingPayload.LastName,
        "Address": bookingPayload.Address + bookingPayload.Address2,
        "City": bookingPayload.City,
        "State": bookingPayload.State,
        "PostalCode": bookingPayload.PostalCode.trim(),
        "Email": bookingPayload.Email,
        "Phone": bookingPayload.Phone,
        "PreferredCommunicationType": "null",
        "EstimateSquareFeet": Number(bookingPayload.EstimateSquareFeet),
        "EstimateBedrooms": Number(bookingPayload.EstimateBedrooms),
        "EstimateBathrooms": Number(bookingPayload.EstimateBathrooms),
        "ReceiveEmailUpdates": true
    };
    const request = {
        method: 'POST',
        url: apiConfig.calculateEstimate,
        data: payload
    };

   return apiRequest(request)
        .then((response: any) => {
            console.log('API success');
            sessionStorage.setItem("mollyEstimate",JSON.stringify(response));
            stopLoader();
        })
        .catch((error:any) => {
            stopLoader();
            console.log('API fail');
            sessionStorage.setItem("noCalendarPricing","true");
        })
    
}

export async function contactUsEndpoint(bookingPayload:any){
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    let noteText, noZipText='';
    if (brandData.note_text_contactus_api.length > 0) {
        noZipText = brandData.osflow_contactUs_comments;
    }
    const requestBody: any = {
        "FirstName": bookingPayload.FirstName,
        "LastName": bookingPayload.LastName,
        "ZipCode": bookingPayload.ZipCode,
        "Phone": bookingPayload.Phone,
        "Email": bookingPayload.Email,
        "city": bookingPayload.City, 
        "state": bookingPayload.State,
        "country": bookingPayload.Country,
        "address": bookingPayload.Address,
        "address2": bookingPayload.Address2,
        "Comments": '',
        "SignUpForUpdates": false,
        "IsLocalized": false,
        "IsNewCustomer": null, 
        "IsTest": isTestFcn(),
        "ConceptId": conceptId,
        "VendorId": brandData.vendorId,
        "VendorName": brandData.vendorName,
        "IsLeadOnly": true,
        "Note": ""
    };
    requestBody.Note = requestBody.Note.concat(noZipText);
    const request = {
        method: 'POST',
        url: apiConfig.CONTACT_US_SEND,
        data: requestBody
    };
    return apiRequest(request)
        .then((response)=>{
            storeBookingApiPayload(requestBody,response);
            window.history.replaceState({}, "" , window.location.href);
            document.querySelectorAll("form").forEach((f)=>{
                f.reset();
            });
            window.location.pathname = '/confirmation';
        })
        .catch((err)=>{
            throw err;
        });
}