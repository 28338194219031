
import apiRequest from "../api/apiRequest";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { FormHandlerStep2 } from "./step2"
import { AppointmentForm } from "./step3"
import { Footer } from "../../opus2/footer/footer";
import { DateSlider } from "./scheduler"
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { getBrandDetailsFromBrandJson, updateHeaderContent } from "../util/share";
import { discoverSuggestions,getAddressDetails,suggestedAddress } from "../util/discovery";

export let allAddress: any = []

export let eventListner: any = null
export let fullData: any = null
export let isLeadData: any = false
export let address1Details:any = null;
// sessionStorage.setItem("suggestion","false");
// let suggestedAddress: any = ''
let flagShowPopup = false;
export let changeZip = false;
sessionStorage.removeItem("currentZip");

export function setchangeZip(value:any) {
    changeZip = value;
}
export function beforeUnloadHandler(e: any) {
    if(!e.target.activeElement.classList.contains("fb-tel") && !e.target.activeElement.classList.contains("form-call-cta")){
    // Define the message to display in the confirmation prompt
    var confirmationMessage = "Are you sure you want to leave this page?";

    // You can conditionally display the confirmation message based on the specific page
    // For example, let's say you only want to display it on a page with a specific URL path:
    var currentURL = window.location.pathname;
    if (currentURL === "/schedule-appointment") {
        // Display the confirmation message
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    }
    }
}

declare global {
    interface Window {
        offersClosetAppointments?: boolean|null,
        calendarTypeId:any
    }
}

export class FormHandlerLead {
    private form: any;
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private phoneNumberInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private serviceAddressInput: HTMLInputElement;
    private serviceAddress2Input: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private showButton: Boolean;
    private onlineScheduling: any;
    private counter:number;
    private HMSLeadFlow:boolean = false;

    constructor() {
        this.handleHMSLeadflow();
        this.onlineScheduling = false;
        this.form = document.querySelectorAll('.contact-us-section .btn-next')
        this.showButton = false
        this.nameInput = document.getElementById('user-full-name') as HTMLInputElement;
        this.phoneNumberInput = document.getElementById('user-phone-number') as HTMLInputElement;
        this.emailInput = document.getElementById('user-email') as HTMLInputElement;
        this.serviceAddressInput = document.getElementById('user-service-address') as HTMLInputElement;
        this.serviceAddress2Input = document.getElementById('user-address-2') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('zip-code') as HTMLInputElement;
        this.lastNameInput = document.getElementById('user-last-name') as HTMLInputElement;
        this.counter = 0;
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        if(!document.querySelector('.confirmation-section')){
            sessionStorage.removeItem("booking");
            sessionStorage.removeItem("os_note");
        }

        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.serviceAddressInput?.addEventListener('keyup', this.addreddErrorRemove2.bind(this))
        this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this))
        this.lastNameInput?.addEventListener('input', this.errorOnLastName.bind(this));
        this.serviceAddress2Input?.addEventListener('keyup', this.address2Error.bind(this));

        if (this.form && this.form[0]) {
            // this.form[0].disabled = true
            // this.form[0].style.opacity = '0.3'
            this.form[0].addEventListener('click', this.handleSubmit.bind(this))
            const cancleBtn = document.querySelectorAll('.contact-us-section .cancel-verification')
            if (cancleBtn) {
                cancleBtn[0].addEventListener('click', () => {
                    sessionStorage.setItem("suggestion","false");
                    this.handleCancelBtnClick();
                })
            }
        }

        if (this.phoneNumberInput) {
            this.phoneNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.serviceAddressInput)
            this.serviceAddressInput.addEventListener('input', this.setAutoSuggested.bind(this));

        // window.addEventListener("beforeunload", beforeUnloadHandler);

        if (sessionStorage.getItem('doingBusinessAs')) {
            this.setHeaderCheck()
        }

        const zzip = sessionStorage.getItem('sAddressParam')

        if (zzip && this.zipCodeInput) {
            this.zipCodeInput!.value = zzip
        }
    }
    public async handleCancelBtnClick() {
        const conceptId = (document.getElementById("conceptCode") as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptId);
        let redirectUrl = localStorage.getItem("dbaName") ? localStorage.getItem("dbaName") : '';

        if(brandData?.weblocation_path_group){
            redirectUrl = `/${brandData.weblocation_path_group}/${redirectUrl}`
        }else {
            redirectUrl = `/${redirectUrl}`
        }

        if(redirectUrl){
            window.location.href = window.location.origin + redirectUrl;
            return 
        }

        if (document.referrer) {
            window.history.back();
        } else {
           window.location.href = window.location.origin;
        }

    }


    setHeaderCheck() {
        const address: any = document.querySelector('.header-wrapper .address')
        const desc = document.querySelector('.header-wrapper .description')

        if (address && desc) {
            address.innerHTML = sessionStorage.getItem('doingBusinessAs')
            desc.innerHTML = 'Locally Owned and Operated'
        }
    }

    errorOnName() {
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {

            const fieldError: any = document.getElementById(`user-full-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid name format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-full-name')
        }

    }

    errorOnLastName() {
        if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            const fieldError: any = document.getElementById(`user-last-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid last name format.'
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-last-name')
        }

    }

    emailError() {
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {

            const fieldError: any = document.getElementById(`user-email-error-msg`);
            //fieldError.classList.remove('hidden')
            //fieldError.innerHTML = 'Invalid email address, please try again.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-email')
        }

    }

    zipCodeError() {
        if (this.zipCodeInput.value.trim() != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {

            const fieldError: any = document.getElementById(`zip-code-error-msg`);
            // fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid zip code format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('zip-code');
            if(this.zipCodeInput.value.trim() != sessionStorage.getItem('currentZip')){
                changeZip = true;
            }else{
                changeZip = false;
            }
        }

        // this.getAutoSuggested()

    }

    addreddErrorRemove2() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }
        else {
            if (suggestedAddress?.address?.label)
                flagShowPopup = true
        }
        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`user-service-address-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-service-address')
            this.hideError('zip-code')
        }

    }


    addreddErrorRemove() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }
       
        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`user-service-address-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-service-address')
            this.hideError('zip-code')
        }

    }


    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')

        setTimeout(() => { this.showSubmitButton.call(this) }, 300)

    }


    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {

            const fieldError: any = document.getElementById(`user-phone-number-error-msg`);
            //fieldError.classList.remove('hidden')
            //fieldError.innerHTML = 'Invalid phone format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError.call(this, 'user-phone-number')
        }

        if (x) {
            this.phoneNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    address2Error() {

        if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2.test(this.serviceAddress2Input?.value)) {

            const fieldError: any = document.getElementById(`user-address-2-error-msg`);
            if(fieldError){
                fieldError.classList?.remove('hidden')
                fieldError.innerHTML = 'Invalid address2 format.'
            }
        }
        else {
            this.hideError('user-address-2')
        }
    }

    showSubmitButton() {

        // if(this.showButton && this.serviceAddressInput.value && REGEX.sendAddress1.test(this.serviceAddressInput.value) &&  this.nameInput.value && REGEX.sendName.test(this.nameInput.value) && this.phoneNumberInput.value && this.emailInput.value &&  REGEX.sendEmail.test(this.emailInput.value) && this.zipCodeInput.value && this.serviceAddressInput.value && REGEX.sendZip.test(this.zipCodeInput.value)){
        //     this.form[0].disabled = false
        //     this.form[0].style.opacity = '1'
        // }
        // else
        // {
        //     this.form[0].disabled = true
        //     this.form[0].style.opacity = '0.3'

        // }
    }

    private handleSubmit(event: Event) {
        event.preventDefault();
        
        if (this.validateForm()) {
            const currentSafeZip = document.getElementById('zip-code') as HTMLInputElement
            sessionStorage.setItem('sAddressParam',currentSafeZip.value);
            sessionStorage.setItem('currentZip',currentSafeZip.value);
            this.zipCodeInput.value = currentSafeZip.value

            const addrFlag = sessionStorage.getItem("suggestion");
           
            if ((addrFlag === "false") && suggestedAddress?.address?.label && flagShowPopup) {
                this.openValidationModal();
    
            } else {
                startLoader()
                const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                this.franchiseLookupAddressApi(zipCode);
            }
        }

    }

    openValidationModal(){
        const callPopupModal = document.getElementById('address-modal')
        const addressData = suggestedAddress?.address?.label;
        callPopupModal?.click();
        const enteredaddres = document.querySelector('[for=entered-address-radio]') as HTMLElement;
        if(enteredaddres) 
            enteredaddres.innerHTML = this.serviceAddressInput.value;
        const suggestedaddress = document.querySelector('[for=suggested-address-radio]') as HTMLElement;
        if(suggestedaddress)
            suggestedaddress.innerHTML = addressData;
        const saveBtn: any = document.querySelector('.property-address-modal a.btn');
        const suggestAddr = this.serviceAddressInput?.getAttribute("data-suggestion") as string;
        const suggestJSON = JSON.parse(suggestAddr);
        saveBtn?.addEventListener('click', () => {
            flagShowPopup = false
            const btn: any = document.getElementById('suggested-address-radio')
            const entAddRadio:any = document.getElementById('entered-address-radio');
            if(entAddRadio?.checked) {
                sessionStorage.setItem("suggestion","false");
                sessionStorage.setItem("manualAddress","true");
            }
            if (btn?.checked && suggestJSON.address.label) {
                this.serviceAddressInput.value = suggestJSON.address.label;
                const suggestedAddress = getAddressDetails(suggestJSON.address);
                sessionStorage.setItem('suggestedAddress',JSON.stringify(suggestedAddress));
                sessionStorage.setItem("suggestion","true");
                sessionStorage.setItem("manualAddress","false");
            }
            if(this.form.length > 0) {
                this.form[0].click();
            }
        })
    }
    setGTMTags(data:any){
        try {
            const leadObj = {
                event:'custEv_LocalDataLayer',
                locationID: data.franchiseWebLocationId,
                locationDBA:data.doingBusinessAs,
                locationZipcode:data.zipCode,
                licenseNumber:data.licenseNumber
            }
            gtmCustomEventLeadFlow(leadObj);

        } catch (error) {
            console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
        }
    }

    callBookingWithoutLocalization(isHMSFlow: boolean, zipCode:any){
        /********* Triggering GTM event ********/
        this.setGTMTags({
            locationID: sessionStorage.getItem('franchiseWebLocationId')??"",
            locationDBA: sessionStorage.getItem('doingBusinessAs')??"",
            locationZipcode: zipCode,
            licenseNumber: sessionStorage.getItem('franchiseWebLocationMarketingId')??""
        });
        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
        sessionStorage.setItem("manualEnteredAddress", addressEntered+", "+zipCode);
        if(isHMSFlow)
            this.contactUsApiCall()
        else{
            try {
                new AppointmentForm(this.onlineScheduling);
                new FormHandlerStep2(this.onlineScheduling);
                new DateSlider();
            } catch (error) {
                console.log(error)
            }
            
        }
    }

    settingLocalizaionInStorage(result:any, zipCode:any){
        const { franchiseWebLocationId, doingBusinessAs, franchiseId } = result;
        sessionStorage.setItem('franchiseWebLocationId', franchiseWebLocationId);
        sessionStorage.setItem('franchiseId', franchiseId);
        sessionStorage.setItem('doingBusinessAs', doingBusinessAs);
        localStorage.setItem('weblocationId', franchiseWebLocationId);

        const locationUrl =  result.locationWebsiteUrl?.replace(/\/$/, '') ?? '';
        const splitUrlArr = locationUrl.toLowerCase().split("/");
        const pathname = splitUrlArr[splitUrlArr.length - 1];
        if(pathname){
            localStorage.setItem('dbaName', pathname);
        }
        
        this.updateInputValue("local_weblocationId", franchiseWebLocationId);
        this.updateInputValue("weblocationId", franchiseWebLocationId);
        this.updateInputValue("full_dbaName", pathname);
        this.updateInputValue("dbaName", pathname);

        const address = document.querySelector('.header-wrapper .address')
        const desc = document.querySelector('.header-wrapper .description')
        
        if(pathname && doingBusinessAs){
            updateHeaderContent(doingBusinessAs);                   
        }

        if (address && desc) {
            address.innerHTML = doingBusinessAs
            desc.innerHTML = 'Locally Owned and Operated'
        }
        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
        sessionStorage.setItem("manualEnteredAddress", addressEntered+", "+zipCode);
        sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
        sessionStorage.setItem("RL_DBAName",doingBusinessAs);
       
    }

    callBookingAfterLocalization(result:any, zipCode:any, brandData:any){
        if (result.length > 0) {
            const { franchiseWebLocationId, doingBusinessAs, licenseNumber } = result[0];
            (async()=>{
            await this.getDetails(franchiseWebLocationId);
            
            this.settingLocalizaionInStorage(result[0], zipCode)
            /********* Triggering GTM event ********/
            this.setGTMTags({
                locationID: franchiseWebLocationId,
                locationDBA: doingBusinessAs,
                locationZipcode: zipCode,
                licenseNumber: licenseNumber
            });
            
            if(this.HMSLeadFlow){
                this.contactUsApiCall()
            }
            else{
                try {
                    
                    new AppointmentForm(this.onlineScheduling);
                    new FormHandlerStep2(this.onlineScheduling);
                    new DateSlider();
                    const ft_footer = new Footer();
                } catch (error) {
                    
                }
            }

        })();
            setTimeout(() => {
                stopLoader();
            }, 3000)
        }
        else {

            stopLoader()
            if(!brandData.lead_contactapi){
                const btnClick = document.getElementById('error-modal-id')
                btnClick?.click()
                const zipMessage = document.getElementById('modal-zipcode-error')
                if (zipMessage){
                    document.getElementById('modal-appoinment-slot')?.classList.add('hidden')
                    zipMessage.innerHTML = `We're sorry. We don't currently provide service to ${zipCode}`
                    zipMessage?.classList.remove('hidden');
                }
            }else{
                this.contactUsApiCall()
            }
        }
    }

    private async franchiseLookupAddressApi(zipCode: any) {
        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };
    
        try {
            const result = await getCachedAPIResponse(request.url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (result) {
                await this.handleLocalization(result, zipCode);
            }
        } catch (error: any) {
            await this.handleError(error);
        }
    }
    
    private async handleLocalization(result: any, zipCode: any) {
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
        const changeLocalization = this.getZipCodeToggeleFlag(brandData);
    
        if (!changeLocalization) {
            this.callBookingAfterLocalization(result, zipCode, brandData);
        } else {
            this.callBookingWithoutLocalization(this.HMSLeadFlow, zipCode);
        }
    }
    
    private async handleError(error: any) {
        stopLoader();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
    
        if (error?.message) {
            if (error.message === 'Full address is needed to narrow down results') {
                const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                const address: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                const completeAddress = `${address}, ${zipCode}`;
                this.franchiseLookupAddressApi(completeAddress);
            }
        } else {
            this.handleGenericError(brandData);
        }
    }
    
    private handleGenericError(brandData: any) {
        stopLoader();
        if (!brandData.lead_contactapi) {
            const btnClick = document.getElementById('error-modal-id');
            btnClick?.click();
            const zipMessage = document.getElementById('modal-zipcode-error');
            if (zipMessage) {
                zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`;
            }
        } else {
            this.contactUsApiCall();
        }
    }

    getZipCodeToggeleFlag(brandData:any):boolean{
        return brandData.send_shortform_lead_bookingapi??false;
    }

    async contactUsApiCall() {
        const step3Instance = new AppointmentForm(false);
        const formData = step3Instance.getValues();
        const city = sessionStorage.getItem('manualCity');
        const state = sessionStorage.getItem('manualState');
        const country = sessionStorage.getItem('countryName');
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="zip-code"]') as HTMLElement)?.innerText.trim();
        const lastName = formData.lastName ?? '';
    
        let vendorId;
        let vendorName;
    
        try {
            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
            if (brandData) {
                vendorId = brandData.vendorId;
                vendorName = brandData.vendorName;
            }
    
            const requestBody: any = this.contactCreateRequestBody(formData, city, state, country, conceptId, vendorId, vendorName, zipCodeText, lastName);           
            if (brandData.lead_contactapi) {
                await this.contactCallContactUsEndpoint(requestBody);
            } else if (this.HMSLeadFlow) {
                await this.HMSCallBookingEndpoint(requestBody);
            }
        } catch (error) {
            console.error(`Error in Step1.ts file in the contactUsApiCall function while fetching the Brand JSON. Error message: ${error}`);
        }
    }
    
    private contactCreateRequestBody(formData: any, city: any, state: any, country: any, conceptId: any, vendorId: any, vendorName: any, zipCodeText: any, lastName: any) {
        const requestBody: any = {
            "FirstName": formData.name.split(' ')[0] ? formData.name.split(' ')[0] : '',
            "LastName": formData.name?.split(' ')[1] ? formData.name?.split(' ')[1] : ' ',
            "ZipCode": formData.zip.trim(),
            "Phone": formData.phone,
            "Email": formData.email,
            "city": city,
            "state": state,
            "country": country,
            "address": formData.address,
            "address2": formData.address2,
            "Comments": zipCodeText,
            "SignUpForUpdates": false,
            "IsLocalized": false,
            "IsTest": this.contactDetermineIsTest(),
            "ConceptId": conceptId?.value,
            "VendorId": vendorId,
            "VendorName": vendorName
        };
    
        if (lastName) {
            requestBody.LastName = lastName;
            requestBody.FirstName = formData.name ?? '';
        }
    
        return requestBody;
    }
    
    private contactDetermineIsTest() {
        const host = window.location.hostname.toLowerCase();
        return host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest') || host.includes('localhost');
    }
    
    private async contactCallContactUsEndpoint(requestBody: any) {
        try {
            const request = {
                method: 'POST',
                url: apiConfig.CONTACT_US_SEND,
                data: requestBody
            };
    
            await apiRequest(request);
            this.setLocalStorageAndRedirectToConfirmation(requestBody, true);
        } catch (error) {
            console.error(`Error in Step1.ts file in the contactCallContactUsEndpoint function while calling the contactus endpoint: ${error}`);
        }
    }
    
    private async HMSCallBookingEndpoint(requestBody: any) {
        let brandData: any = localStorage.getItem("brandDetails");
        if (brandData) brandData = JSON.parse(brandData);
    
        requestBody.LeadSource = "Web";
        requestBody.LeadOrigin = "Web";
        requestBody.EmailOptOut = (brandData?.enable_emailOptOut) ?? true;
        requestBody.IsLeadOnly = (brandData?.default_leadSourceID) ?? true;
        requestBody.LeadSourceId = 0;
        requestBody.FranchiseId = (sessionStorage.getItem("franchiseId")) ?? "";
        requestBody.WebLocationId = sessionStorage.getItem("franchiseWebLocationId") ?? "";
        requestBody.State = sessionStorage.getItem("stateCode") ?? sessionStorage.getItem("manualState") ?? "";
        requestBody.Country = sessionStorage.getItem("manualCountryCode") ?? sessionStorage.getItem("countryName") ?? "";
        requestBody.City = sessionStorage.getItem("manualCity") ?? "";
        requestBody.CampaignName = "N/A";
        requestBody.CallType = "Inbound";
        requestBody.ConceptCalledId = requestBody.ConceptId;
    
        delete requestBody.Comments;
        delete requestBody.city;
        delete requestBody.country;
        delete requestBody.state;
        delete requestBody.IsLocalized;
    
        if (requestBody.FranchiseId !== "") {
            requestBody.FranchiseId = parseInt(requestBody.FranchiseId);
        }
        if (requestBody.WebLocationId !== "") {
            requestBody.WebLocationId = parseInt(requestBody.WebLocationId);
        }
        if (requestBody.EmailOptOut === "") {
            requestBody.EmailOptOut = true;
        }
        if (requestBody.IsLeadOnly === "") {
            requestBody.IsLeadOnly = true;
        }
    
        try {
            const request = {
                method: 'POST',
                url: apiConfig.BOOKING_API_URL,
                data: requestBody
            };
            startLoader();
            await apiRequest(request);
            this.setLocalStorageAndRedirectToConfirmation(requestBody, false);
        } catch (error) {
            stopLoader();
            console.error(`Error in Step1.ts file in the HMSCallBookingEndpoint function while calling the booking endpoint for the HMS ISN Lead Flow: ${error}`);
        }
    }
    

    setLocalStorageAndRedirectToConfirmation(requestBody:any, isContact:boolean){
        const conceptIdElement = document.getElementById('conceptId') as HTMLInputElement;
        const conceptId: string | undefined = conceptIdElement?.value;
    
        if (conceptId === "25") {
            requestBody.isContact = isContact;
            requestBody.Address = requestBody.address;
            requestBody.PostalCode = requestBody.ZipCode
            sessionStorage.setItem("suggestion","false");
            sessionStorage.setItem('booking', JSON.stringify(requestBody));
        }
        else{
            const contactUsObj = {       
                "FirstName": requestBody.FirstName,
                "LastName": requestBody.LastName,
                "ZipCode": requestBody.ZipCode,
                "Phone":requestBody.Phone,
                "Email": requestBody.Email,
                "isContact":isContact
            };
        
            sessionStorage.setItem('booking', JSON.stringify(contactUsObj));
        }
        window.history.replaceState({}, '', '/confirmation');
        window.location.href = '/confirmation'
        setTimeout(() =>{
            stopLoader();
        },1000);
    }

    private async getDetails(franchiseWeblocationId: any) {
        const url = `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${franchiseWeblocationId}`;
        try{
            const resp: any = await getCachedAPIResponse(url, apiCaching.FullAttributeResponse);

            let callTrackNumber: any = resp?.mainPhoneNumber;
            this.onlineScheduling = resp?.onlineScheduling;
            const offersClosetAppointments = resp?.options?.offersClosetAppointments;
            window.offersClosetAppointments = offersClosetAppointments;
            window.calendarTypeId = resp?.calendarTypeId;
            console.log(resp?.options?.showFees);
            const  showFeeFlag = resp?.options?.showFees;
            sessionStorage.setItem("hideInspectionFee",showFeeFlag);
            resp?.callTrackings.forEach((type: any) => {
                if (type.callTrackingTypeDescription == "WebsiteTracking") {
                    callTrackNumber = type.callTrackingNumber;
                    sessionStorage.setItem('localPhoneNumber', callTrackNumber);
                }
                const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

                if (phoneNO) {
                    phoneNO.href = `tel:${callTrackNumber}`
                    const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                    const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                    phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
                }

                const mobile: any = document.querySelector('.header-cta-mobile .primary-btn')

                if (mobile) {
                    mobile.href = `tel:${callTrackNumber}`

                }
                if(this.HMSLeadFlow){
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                }
                else
                if (!resp?.onlineScheduling) {
                    const progressContainer = document.querySelector('.progressbar')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const lastChild: any = progress[2]
                    if (lastChild)
                        progressContainer?.removeChild(lastChild)
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[1]
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    if (btnNext)
                        btnNext.innerHTML = 'Submit'
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = true
                }
                else {
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const progressBar: any = document.getElementById('progress')
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    progressBar.style.width = "50%"
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = false
                }
            }); 
            return resp;
        }catch (error) {
            console.error("Error in getDetails:", error);
            throw error; // Propagate the error to the caller
        }
    }

    private validateForm(): boolean {
        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};


        if (this.nameInput.required && this.nameInput.value === '') {
            errors['user-full-name'] = '';
        } else if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            errors['user-full-name'] = 'Invalid name format.';
        }

        if (this.lastNameInput?.required && this.lastNameInput?.value === '') {
            errors['user-last-name'] = '';
        } else if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            errors['user-last-name'] = 'Invalid last name format.';
        }

        if (this.phoneNumberInput.required && this.phoneNumberInput.value === '') {
            errors['user-phone-number'] = '';
        }
        else if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {

            const fieldError: any = document.getElementById(`user-phone-number-error-msg`);
            errors['user-phone-number'] = 'Invalid phone format.'


        }

        if (this.zipCodeInput?.required && this.zipCodeInput?.value === '') {
            errors['zip-code'] = '';
        } else if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
            errors['zip-code'] = 'Invalid zip code format.';
        }

        if (this.serviceAddressInput.required && this.serviceAddressInput.value === '') {
            errors['user-service-address'] = '';
        }
        else if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {
            errors['user-service-address'] = 'Invalid address format.'

        }

        if (this.serviceAddress2Input?.required && this.serviceAddress2Input?.value === '') {
            errors['user-address-2'] = '';
        }
        else if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2?.test(this.serviceAddress2Input?.value)) {
            errors['user-address-2'] = 'Invalid address2 format.'
        }

        if (this.emailInput.required && this.emailInput.value === '') {
            errors['user-email'] = '';
        } else if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            errors['user-email'] = 'Invalid email address, please try again.';
        }


        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field) {
                if(field.parentNode){
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError)
                    if(fieldError.classList.contains('hidden'))
                        fieldError.classList.remove('hidden')
            }
            }
            if (index == 0 && field) {
                field.focus()
            }
        });

        return Object.keys(errors).length === 0;
    }

    private setAutoSuggested = async (e: any) => {

        e.preventDefault()
        sessionStorage.setItem("suggestion", "false");
        this.serviceAddressInput.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        
        discoverSuggestions(this.zipCodeInput,this.serviceAddressInput);
        
    }

    setZipCode = () => {
        let isFlag = true
        flagShowPopup = false
        const input: any =  document.querySelectorAll('.input-label-wrap #zip-code')[0];
        if (input && input.id == 'zip-code') {
            isFlag = false
        }

        const address = this.serviceAddressInput.value
        const item = allAddress.filter((item: any) => item.title == address)[0]
        const val = String(item?.address?.postalCode).split('-')[0]
        if (val && isFlag)
            this.zipCodeInput.value = val

        setTimeout(() => {
            this.addreddErrorRemove.bind(this)
        }, 300)
    }

    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;
    
            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    handleHMSLeadflow(): void {
        const conceptIdElement = document.getElementById('conceptId') as HTMLInputElement;
        const conceptId: string | undefined = conceptIdElement?.value;
        const sessionHMSZip = sessionStorage.getItem("hms_lead_ISN_zip");
    
        if (conceptId !== "25") {
            return;
        }
    
        const zipInputField = document.querySelector("#isn-form #zip-code") as HTMLInputElement;
        if (zipInputField && sessionHMSZip) {
            zipInputField.value = sessionHMSZip;
        }
        this.HMSLeadFlow = true;
        const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[0]as HTMLButtonElement;
        if (btnNext){
            btnNext.innerHTML = 'Submit';
            btnNext.title = "Click to Submit Form";
        }
        const progressContainer = document.querySelector('.progressbar')
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const lastChild: any = progress[2];
        if (lastChild)
            progressContainer?.removeChild(lastChild)
    }
    

}
// Address dropdown dismmised 
document?.body?.addEventListener('click', function(event) {
    const suggestionContainer = document.querySelector('.suggestion-container') as HTMLElement;
    if(suggestionContainer?.parentNode){
        suggestionContainer.parentNode.removeChild(suggestionContainer);
    }
})

// Initialize the form handler when the DOM is ready and for any brands other than HMS and MLY
document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId !== "25" && conceptId !== "1") {
        new FormHandlerLead();
    }
});


